import {
  CheckIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { FC, RefObject, SyntheticEvent, useRef, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { Divider } from "@amenda-components/App";
import { Option } from "@amenda-types";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

interface Props {
  label?: string;
  selectedOption?: string | string[];
  options: Option[];
  secondaryOptions?: Option[];
  closeOnHover?: boolean;
  Icon?: FC<any>;
  withIcon?: boolean;
  disabled?: boolean;
  labelClassName?: string;
  menuItemsClassName?: string;
  hasMenuOverflow?: boolean;
  onChange: (option: Option) => void;
}

const getStyle = (
  hasMenuOverflow: boolean,
  parentRef?: RefObject<HTMLDivElement>,
) => {
  const parentRect = parentRef?.current?.getBoundingClientRect?.();

  if (hasMenuOverflow && parentRect) {
    return {
      top: parentRect.top,
    };
  }
  return {};
};

export const OperationsMenu: FC<Props> = ({
  label,
  selectedOption,
  options,
  secondaryOptions,
  disabled = false,
  closeOnHover = false,
  withIcon = false,
  hasMenuOverflow = false,
  menuItemsClassName = "origin-top-right right-0",
  labelClassName = "px-1 py-[1px] bg-white text-gray-900 hover:bg-gray-900 hover:text-white text-xxs focus:outline-none",
  Icon = EllipsisVerticalIcon,
  onChange,
}) => {
  const { t } = useTranslation();
  const [hoverAway, setHoverAway] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const hiddenMenuItemRef = useRef<HTMLDivElement>(null);

  const isMulti = Array.isArray(selectedOption);

  const getLabel = () => {
    const defaultLabel = label || "No selected option";

    if (isMulti) {
      const foundOption = options.find(({ value }) =>
        selectedOption.includes(value),
      );

      return !foundOption
        ? defaultLabel
        : selectedOption.length > 1
          ? `${foundOption.label},  ...`
          : foundOption.label;
    }
    return (
      options.find(({ value }) => value === selectedOption)?.label ||
      defaultLabel
    );
  };

  const getSelectedOptions = (value: string) => {
    if (isMulti) {
      return selectedOption.includes(value);
    }
    return selectedOption && selectedOption === value;
  };

  const handleClick =
    (option: Option, close: () => void) =>
    (e: SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(option);
      close();
    };

  const handleMenuFocusAndBlur = (hoverAway: boolean) => () => {
    if (!disabled && closeOnHover) {
      setHoverAway(hoverAway);
      hiddenMenuItemRef?.current?.click();
    }
  };

  return (
    <Menu
      ref={parentRef}
      as="div"
      className="relative"
      onMouseLeave={handleMenuFocusAndBlur(true)}
      onMouseEnter={handleMenuFocusAndBlur(false)}
    >
      {({ open }) => (
        <>
          {withIcon ? (
            <MenuButton
              disabled={disabled}
              className={clsx(
                "flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none",
                {
                  "cursor-not-allowed": disabled,
                },
              )}
            >
              <span className="sr-only">{t("Open options")}</span>
              <Icon className="h-4 w-4 text-gray-500" aria-hidden="true" />
            </MenuButton>
          ) : (
            <MenuButton
              disabled={disabled}
              className={clsx(
                "group inline-flex items-center",
                labelClassName,
                {
                  "cursor-not-allowed": disabled,
                },
              )}
            >
              <span>{t(getLabel())}</span>
              <Divider />
              <ChevronRightIcon
                className={clsx("ml-1 h-2 w-2", {
                  "rotate-90": open,
                })}
                aria-hidden="true"
              />
            </MenuButton>
          )}
          <Transition
            show={open && !hoverAway}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              static
              className={clsx(
                "z-50 max-h-48 w-36 divide-y divide-gray-100 overflow-y-auto overflow-x-hidden overscroll-contain bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                menuItemsClassName,
                {
                  fixed: hasMenuOverflow,
                  absolute: !hasMenuOverflow,
                },
              )}
              style={{
                ...getStyle(hasMenuOverflow, parentRef),
              }}
            >
              <MenuItem>
                {({ close }) => (
                  <div
                    className="hidden"
                    onClick={close}
                    ref={hiddenMenuItemRef}
                  />
                )}
              </MenuItem>
              <div className="py-1">
                {options.map((option) => (
                  <MenuItem key={option.value}>
                    {({ focus, close }) => (
                      <div
                        onClick={handleClick(option, close)}
                        className={clsx(
                          "group/menuItem flex w-full cursor-pointer justify-between hover:text-white",
                          {
                            "bg-gray-900 text-white": focus,
                            "text-gray-900": getSelectedOptions(option.value),
                            "text-gray-700": !focus,
                          },
                        )}
                      >
                        <span className="truncate px-2 py-2 text-sm">
                          {t(option.label)}
                        </span>
                        {getSelectedOptions(option.value) && (
                          <div className="mr-3 mt-2">
                            {isMulti ? (
                              <XIcon
                                aria-hidden="true"
                                className="h-4 w-4 text-gray-900"
                              />
                            ) : (
                              <CheckIcon
                                aria-hidden="true"
                                className="h-4 w-4 text-gray-900 group-hover/menuItem:text-white"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                ))}
                {secondaryOptions && !isEmpty(options) && (
                  <div className="my-0.5 border-t border-gray-300" />
                )}
                {secondaryOptions?.map((option) => (
                  <MenuItem key={option.value}>
                    {({ close }) => (
                      <div
                        onClick={handleClick(option, close)}
                        className="group flex w-full cursor-pointer justify-between hover:bg-gray-900 hover:text-white"
                      >
                        <div className="px-2 py-2 text-sm">
                          {t(option.label)}
                        </div>
                        {getSelectedOptions(option.value) && (
                          <div className="mr-3 mt-2">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-4 w-4 text-gray-900 group-hover:text-gray-500"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};
