import { EmptyIndicator } from "@amenda-components/Shared";
import { FC } from "react";
import { TextField } from "@amenda-components/FormComponents";
import { Tooltip } from "@amenda-components/App";
import { formatNumbers } from "@amenda-utils";
import get from "lodash/get";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  error?: string;
  endAddOnText?: string;
  id: string;
  operation?: string;
  readOnly: boolean;
  disabled?: boolean;
  value: any;
  tooltipMessage?: string;
  onChange: (value: number) => void;
  onBlur?: () => void;
}

const ReadOnlyCostGroupField: FC<
  Pick<Props, "id" | "endAddOnText" | "tooltipMessage">
> = ({ id, endAddOnText, tooltipMessage }) => {
  const projectCostGroupForm =
    useProjectStore((state) => state.projectCostGroupForm) || {};
  const value = get(projectCostGroupForm, id);

  return (
    <Tooltip
      id={`costgroup_${id}`}
      position="right"
      className="w-full"
      message={value && tooltipMessage}
    >
      <div className="py-2">
        {(value && formatNumbers(value)) || <EmptyIndicator />}
        {value && endAddOnText}
      </div>
    </Tooltip>
  );
};

export const CostGroupInputField: FC<Props> = ({
  id,
  error,
  value,
  endAddOnText,
  readOnly,
  disabled,
  onChange,
  onBlur,
  tooltipMessage,
}) => {
  const message = error || tooltipMessage;

  if (readOnly) {
    return (
      <ReadOnlyCostGroupField
        id={id}
        endAddOnText={endAddOnText}
        tooltipMessage={tooltipMessage}
      />
    );
  }
  return (
    <div className="flex w-full justify-end">
      <div className="max-w-sm md:min-w-48">
        <Tooltip
          id={`costgroup_${id}`}
          position="right"
          className="w-full"
          message={message}
        >
          <TextField
            id={id}
            value={value}
            type="number"
            size="xs"
            isClearable={false}
            inputWrapperClassName="flex-row"
            inputClassName="text-xs text-right pr-8"
            readOnly={readOnly}
            endAddOnText={endAddOnText}
            disabled={disabled}
            error={message}
            hideErrorMessage={true}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Tooltip>
      </div>
    </div>
  );
};
