import { OperationContext, gql, useClient, useSubscription } from "urql";
import {
  TIMELINE_ACTIVITY_FRAGMENT,
  TIMELINE_ACTIVITY_SUBSCRIPTION,
} from "@amenda-domains/fragments/app";
import { useCallback, useState } from "react";
import {
  useDashboardStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { KEYWORD_FRAGMENT } from "@amenda-domains/fragments/forms";
import { SEARCH_PROJECT_FRAGMENT } from "@amenda-domains/fragments/projects";
import { SearchCollections } from "@amenda-constants";
import { WIDGET_FRAGMENT } from "@amenda-domains/fragments/settings";
import { getSearchAggregation } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations/app";

const SEARCH_PROJECTS_AND_USERS = gql`
  ${SEARCH_PROJECT_FRAGMENT}
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      projects {
        ...SearchProjectFragment
      }
      users
      contacts
    }
  }
`;

export const GET_KEYWORDS = gql`
  ${KEYWORD_FRAGMENT}
  query GetKeywords($ids: [ID!], $componentIds: [ID!], $resourceId: ID) {
    getKeywords(
      args: { _ids: $ids, componentIds: $componentIds, resourceId: $resourceId }
    ) {
      ...KeywordFragment
    }
  }
`;

export const useSearchProjectsAndUsers = () => {
  const setSearchResults = useAppStore((state) => state.setSearchResults);
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const searchProjectsAndUsers = useCallback(
    async (searchTerm: string) => {
      setLoading(true);
      const projectAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "name",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "number",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "address.name",
          },
        },
      ];
      const projectResponse = [
        {
          $project: {
            name: 1,
            number: 1,
            galleryUrl: 1,
            "formValues.name": 1,
            "formValues.number": 1,
            "formValues.address": 1,
            "formValues.status": 1,
            "formValues.gross_floor_area": 1,
            "formValues.net_floor_area": 1,
            "formValues.property_size": 1,
          },
        },
      ];
      const companyAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "contactDetails.companyName",
          },
        },
      ];
      const officeAndPersonsAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "firstName",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "lastName",
          },
        },
      ];

      const officeMatch = {
        $match: {
          isDeleted: false,
          isActive: { $exists: true, $eq: true },
        },
      };
      const isDeletedFalseMatch = {
        $match: {
          isDeleted: false,
        },
      };

      const { data } = await client
        .query(
          SEARCH_PROJECTS_AND_USERS,
          {
            collections: [
              SearchCollections.Projects,
              SearchCollections.Users,
              SearchCollections.Contacts,
            ],
            aggregations: {
              [SearchCollections.Projects]: getSearchAggregation(
                projectAutoComplete,
                isDeletedFalseMatch,
              ).concat(projectResponse),
              [SearchCollections.Contacts]: getSearchAggregation(
                [...officeAndPersonsAutoComplete, ...companyAutoComplete],
                isDeletedFalseMatch,
              ),
              [SearchCollections.Users]: getSearchAggregation(
                officeAndPersonsAutoComplete,
                officeMatch,
              ),
            },
          },
          {
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      if (data?.search) {
        setSearchResults({
          projects: data.search?.projects,
          users: [
            ...(data.search?.users ?? []),
            ...(data.search?.contacts ?? []),
          ].map((u: any) => ({
            ...u,
            id: u._id,
          })),
        });
      }
      setLoading(false);
    },
    [client, setSearchResults],
  );

  return {
    loading,
    searchProjectsAndUsers,
  };
};

interface GetKeywordsArgs {
  ids?: string[];
  componentIds?: string[];
  resourceId?: string;
  skipStorage?: boolean;
  setKeywords?: (keywords: any[]) => void;
}

export const useGetKeywords = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getKeywords = useCallback(
    async ({
      skipStorage = false,
      setKeywords,
      ...variables
    }: GetKeywordsArgs) => {
      setLoading(true);
      const { data } = await client.query(GET_KEYWORDS, variables).toPromise();
      if (!skipStorage && setKeywords) {
        setKeywords(data?.getKeywords ?? []);
      }
      setLoading(false);

      return data?.getKeywords;
    },
    [client],
  );

  return {
    loading,
    getKeywords,
  };
};

/*
hook for getting user widgets using urql
example query
Query.getUserWidgets(
type: String!
): [Widget!]!
*/

export const GET_USER_WIDGETS = gql`
  ${WIDGET_FRAGMENT}
  query GetUserWidgets($type: String!) {
    widgets: getUserWidgets(type: $type) {
      ...WidgetFragment
    }
  }
`;

export const useGetUserWidgets = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setWidget = useDashboardStore((state) => state.setWidget);

  const getUserWidgets = useCallback(
    async (variables: Record<string, any>) => {
      setLoading(true);

      const { data } = await client
        .query(GET_USER_WIDGETS, variables)
        .toPromise();

      setWidget(data?.widgets?.[0] || {});
      setLoading(false);
    },
    [client, setWidget],
  );

  return {
    loading,
    getUserWidgets,
  };
};

export const GET_ALL_TIMELINE_ACTIVITIES = gql`
  ${TIMELINE_ACTIVITY_FRAGMENT}
  query GetAllTimelineActivities(
    $isDeleted: Boolean
    $limit: Int
    $next: String
    $previous: String
    $resourceId: ID
    $selectTimelineActivityFields: Object
    $sort: Object!
    $type: TimelineType
  ) {
    getAllTimelineActivities(
      isDeleted: $isDeleted
      limit: $limit
      next: $next
      previous: $previous
      resourceId: $resourceId
      selectTimelineActivityFields: $selectTimelineActivityFields
      sort: $sort
      type: $type
    ) {
      docsCount
      hasNext
      hasPrevious
      next
      previous
      timelineActivities {
        ...TimelineActivityFragment
      }
    }
  }
`;

export const useGetAllTimelineActivities = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setActivityTimelines = useAppStore(
    (state) => state.setActivityTimelines,
  );

  const getAllTimelineActivities = useCallback(
    async (variables: Record<string, any>) => {
      setLoading(true);

      const { data } = await client
        .query(GET_ALL_TIMELINE_ACTIVITIES, variables)
        .toPromise();

      if (data?.getAllTimelineActivities) {
        setActivityTimelines(data.getAllTimelineActivities);
      }
      setLoading(false);
    },
    [client, setActivityTimelines],
  );

  return {
    loading,
    getAllTimelineActivities,
  };
};

const TIMELINE_ACTIVITY_CREATED_SUBS = gql`
  ${TIMELINE_ACTIVITY_SUBSCRIPTION}
  subscription TimelineActivityCreated($ownerId: String!, $tenantId: String!) {
    timelineActivityCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...TimelineActivitySubFragment
    }
  }
`;

const TIMELINE_ACTIVITY_UPDATED_SUBS = gql`
  ${TIMELINE_ACTIVITY_SUBSCRIPTION}
  subscription TimelineActivityUpdated($ownerId: String!, $tenantId: String!) {
    timelineActivityUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...TimelineActivitySubFragment
    }
  }
`;

const TIMELINE_ACTIVITY_DELETED_SUBS = gql`
  subscription TimelineActivityDeleted($ownerId: String!, $tenantId: String!) {
    timelineActivityDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
      tenantId
    }
  }
`;

export const useTimelineActivityCreatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: TIMELINE_ACTIVITY_CREATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useTimelineActivityUpdatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: TIMELINE_ACTIVITY_UPDATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useTimelineActivityDeletedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: TIMELINE_ACTIVITY_DELETED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

/**
 * 
 * write a hook for this query
 * 
 * getCommonObjectsValues(
input: CommonObjectsValuesInput!
): CommonObjectsValuesResult
 * 
 */
export const GET_COMMON_OBJECTS_VALUES = gql`
  query GetCommonObjectsValues(
    $collection: String
    $componentIds: [String!]
    $ids: [String!]!
  ) {
    getCommonObjectsValues(
      input: { ids: $ids, collection: $collection, componentIds: $componentIds }
    ) {
      values
    }
  }
`;

export const useGetCommonObjectsValues = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getCommonObjectsValues = useCallback(
    async ({
      context = {},
      ...variables
    }: {
      collection: string;
      componentIds: string[];
      ids: string[];
      context?: Partial<OperationContext>;
    }) => {
      setLoading(true);

      const { data } = await client
        .query(GET_COMMON_OBJECTS_VALUES, variables, context)
        .toPromise();

      setLoading(false);

      return data?.getCommonObjectsValues?.values?.[0] ?? {};
    },
    [client],
  );

  return {
    loading,
    getCommonObjectsValues,
  };
};

export const GET_KEYWORDS_COUNT = gql`
  query GetKeywordsCount($input: KeywordsCountInput!) {
    getKeywordsCount(input: $input) {
      collection
      keywordCount
    }
  }
`;

export const useGetKeywordsCount = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getKeywordsCount = useCallback(
    async ({
      callback,
      ...input
    }: {
      collections: {
        collection: string;
        keywordPath: string;
      }[];
      callback: (
        data: {
          collection: string;
          keywordCount: number;
        }[],
      ) => void;
    }) => {
      setLoading(true);
      const { data } = await client
        .query(
          GET_KEYWORDS_COUNT,
          { input },
          {
            requestPolicy: "cache-and-network",
          },
        )
        .toPromise();
      setLoading(false);

      callback(data?.getKeywordsCount ?? []);
    },
    [client],
  );

  return {
    loading,
    getKeywordsCount,
  };
};
