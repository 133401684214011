import { ChevronDownIcon, XIcon } from "lucide-react";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormTab, TabItem, availableLayouts } from "@amenda-types";
import {
  IconButtonBase,
  Image,
  Link,
  SlideOver,
  Tag,
} from "@amenda-components/App";
import { addQueryToPath, getStaticProjectTabs } from "./common";
import { useAppStore, useProjectStore } from "@amenda-domains/mutations";
import { useLocation, useNavigate } from "react-router-dom";

import { SidebarContainer } from "@amenda-components/Shared";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { getQueryParams } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  resourceId?: string;
  availableForms: FormTab[];
  projectsGeneralPermissions: any;
}

type CustomTabItem = TabItem & { order?: number; children?: any[] };

const ProjectSectionSidebarContent: FC<Props> = ({
  resourceId,
  availableForms,
  projectsGeneralPermissions,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [expandedSection, setExpandedSection] = useState<
    Record<string, boolean>
  >({});
  const setOpenProjectFormSection = useProjectStore(
    (state) => state.setOpenProjectFormSection,
  );
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);
  const projectTabs = useMemo(() => {
    let tabs: CustomTabItem[] = [];

    (availableForms ?? []).forEach((form) => {
      tabs = [
        ...tabs,
        {
          label: form.name,
          value: form.id,
          order: form.order,
          children: (form.components ?? []).filter((c) =>
            [
              availableLayouts.twoColumnsFullWidth,
              availableLayouts.nestedForm,
              availableLayouts.fullColumnGrid,
            ].includes(c.layout),
          ),
        },
      ];
    });

    tabs = [...tabs, ...getStaticProjectTabs(projectsGeneralPermissions)];
    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const foundIndex = projectTabs.findIndex(
    (tab) => tab.value === getQueryParams(search)?.tab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  const handleChange = (tab: CustomTabItem) => {
    if (tab.children && tab.children.length > 1) {
      setExpandedSection((prev) => ({
        ...prev,
        [tab.value]: !Boolean(prev[tab.value]),
      }));
    } else {
      setOpenProjectFormSection(false);
      navigate(addQueryToPath(pathname, { tab: tab.value }), { replace: true });
    }
  };

  const handleChangeComponent = (tab: TabItem, component: any) => {
    setOpenProjectFormSection(false);
    navigate(addQueryToPath(pathname, { tab: tab.value }), { replace: true });
    setTimeout(() => {
      document.getElementById(component.id)?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  };

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    },
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.7, // Trigger when 70% of the target is visible
    });

    if (!isFetchingProject && defaultTabIndex >= 0) {
      projectTabs.forEach((tab) => {
        if (tab.children && tab.children.length > 1) {
          tab.children.forEach((child) => {
            const element = document.getElementById(child.id);
            if (element) observer.observe(element);
          });
        }
      });
    }
    return () => observer.disconnect();
  }, [isFetchingProject, defaultTabIndex, projectTabs, handleIntersection]);

  useEffect(() => {
    const defaultTab = projectTabs[defaultTabIndex];
    if (defaultTab && defaultTab.children && defaultTab.children.length > 1) {
      setExpandedSection((prev) => ({
        ...prev,
        [defaultTab.value]: true,
      }));
    }
  }, [defaultTabIndex, projectTabs]);

  return (
    <>
      <div
        className={clsx("flex flex-col space-y-2", {
          "pt-6": !!resourceId,
        })}
      >
        {projectTabs.map((tab, i) => (
          <Fragment key={tab.value}>
            <Link
              variant="secondaryAlt"
              className={clsx(
                "flex w-full items-center rounded-sm pr-2 text-left",
                {
                  "bg-gray-900 text-white": defaultTabIndex === i,
                },
              )}
              onClick={() => handleChange(tab)}
              disabled={!resourceId ? tab.order !== 0 : false}
            >
              {t(tab.label)}
              {tab.children && tab.children.length > 1 && (
                <ChevronDownIcon
                  className={clsx(
                    "ml-auto h-5 w-5 transition-transform delay-75 duration-200 ease-in-out",
                    {
                      "rotate-180": Boolean(expandedSection[tab.value]),
                    },
                  )}
                />
              )}
            </Link>
            {tab.children && tab.children.length > 1 && (
              <Transition
                as="div"
                className="flex flex-col space-y-2"
                show={Boolean(expandedSection[tab.value])}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <>
                  {tab.children.map((c) => (
                    <Link
                      key={c.id}
                      variant="secondaryAlt"
                      className={clsx(
                        "flex w-full items-center rounded-sm pl-4 text-left transition delay-150 duration-300 ease-in-out",
                        {
                          "text-gray-900": activeSection === c.id,
                        },
                      )}
                      disabled={!resourceId ? tab.order !== 0 : false}
                      onClick={() => {
                        handleChangeComponent(tab, c);
                      }}
                    >
                      {c?.properties?.label}
                    </Link>
                  ))}
                </>
              </Transition>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

export const ProjectSectionSidebar: FC<Props> = (props) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <SidebarContainer className="h-screen">
      <div className="mr-4 h-full max-h-screen w-[300px] overflow-y-auto overscroll-y-contain border-r border-gray-200 p-4">
        {props?.resourceId && (
          <div className="flex w-full space-x-1">
            <div
              className={clsx("relative", {
                group: sidebarCollapsed,
              })}
            >
              <div
                className={clsx("transition", {
                  "group-hover:opacity-10": sidebarCollapsed,
                })}
              >
                <Image
                  url={selectedProject?.galleryUrl}
                  magicSize="thumbnail"
                  background="bg-white"
                  variant="contain"
                  size="h-[60px] min-h-[60px] w-[60px] min-w-[60px]"
                />
              </div>
              <div
                className={clsx(
                  "bg-gray-10 absolute inset-0 hidden items-center justify-center transition",
                  {
                    "group-hover:flex": sidebarCollapsed,
                  },
                )}
              >
                {sidebarCollapsed && (
                  <div className="flex flex-row items-center pr-1 pt-3">
                    <img
                      alt=""
                      className="mb-4 h-4 w-4 cursor-pointer"
                      onClick={toggleSidebar}
                      src={
                        sidebarCollapsed
                          ? "/images/sidebarExpand.svg"
                          : "/images/sidebarCollapse.svg"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex grow flex-col pl-3">
              {selectedProject?.name && (
                <div className="line-clamp-2 text-sm leading-tight">
                  {selectedProject?.name}
                </div>
              )}
              {selectedProject?.number && (
                <div className="mt-auto pt-[1px]">
                  <Tag children={selectedProject.number} />
                </div>
              )}
            </div>
          </div>
        )}
        <ProjectSectionSidebarContent {...props} />
      </div>
    </SidebarContainer>
  );
};

export const ProjectSectionSidebarMobile: FC<Props> = (props) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const openProjectFormSection = useProjectStore(
    (state) => state.openProjectFormSection,
  );
  const setOpenProjectFormSection = useProjectStore(
    (state) => state.setOpenProjectFormSection,
  );

  const handleClose = () => {
    setOpenProjectFormSection(false);
  };

  return (
    <SlideOver show={openProjectFormSection} onClose={handleClose}>
      <div className="relative h-screen w-full overflow-y-auto p-2">
        <div className="flex w-full space-x-1">
          <Image
            url={selectedProject?.galleryUrl}
            magicSize="thumbnail"
            background="bg-white"
            variant="contain"
            size="h-[80px] min-h-[80px] w-[80px] min-w-[80px]"
          />
          <div className="flex grow flex-col pl-3">
            <IconButtonBase
              className="ml-auto"
              variant="clean"
              label="Close"
              onClick={handleClose}
            >
              <XIcon className="h-6 w-6" />
            </IconButtonBase>
            <div className="line-clamp-2 text-sm leading-tight">
              {selectedProject?.name}
            </div>
            <div className="pt-[1px]">
              <Tag children={selectedProject?.number} />
            </div>
          </div>
        </div>
        <ProjectSectionSidebarContent {...props} />
      </div>
    </SlideOver>
  );
};
