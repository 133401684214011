import { FC, useEffect, useState } from "react";
import { HelperMessage, Tooltip } from "@amenda-components/App";
import { ListCollapseIcon, XIcon } from "lucide-react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { groupBy, isEmpty } from "lodash";

import { MiniSearchField } from "@amenda-components/SearchComponents";
import { buttonTheme } from "@amenda-styles/theme";
import clsx from "clsx";
import { findMatchingComponents } from "@amenda-components/PageBuilder/common";
import { useTranslation } from "react-i18next";

interface Props {
  groupKey?: string;
  components: any[];
  selectedComponents?: any[];
  handleGrouping: (
    component: any,
    remove?: boolean,
    close?: () => void,
  ) => () => void;
}

export const GroupByDropdown: FC<Props> = ({
  components,
  selectedComponents,
  groupKey = "",
  handleGrouping,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [groupedComponents, setGroupedComponents] = useState<any>({});

  const keys = Object.keys(groupedComponents);
  const selectedComponentIds = selectedComponents?.map((c) => c.id);

  const handleSearch = (value: string) => {
    const filteredComponents =
      value.length >= 1
        ? findMatchingComponents(components, value)
        : components;
    const groupedComponents = groupBy(filteredComponents, groupKey);

    setSearchTerm(value);
    setGroupedComponents(groupedComponents);
  };

  useEffect(() => {
    const groupedComponents = groupBy(components, groupKey);

    setGroupedComponents(groupedComponents);
  }, [groupKey, components]);

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, []);

  return (
    <Popover as="div" className="relative">
      {() => (
        <>
          <div className="flex items-center">
            <Tooltip id="groupBy" message="Group by" position="top">
              <PopoverButton
                className={buttonTheme({
                  variant: "default",
                  size: "xs",
                  className:
                    "flex flex-row items-center border border-gray-300 outline-none",
                })}
              >
                <ListCollapseIcon className="h-5 w-5" />
              </PopoverButton>
            </Tooltip>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <PopoverPanel
              as="div"
              className="fixed z-50 w-80 origin-top-left divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {({ close }) => (
                <div className="w-full">
                  <div className="max-h-80 min-h-[10rem] overflow-auto">
                    <div className="sticky top-0 z-20 border-b border-gray-200 bg-white text-xs text-gray-500">
                      <MiniSearchField
                        value={searchTerm}
                        className=""
                        placeholder={t("Search components") + "..."}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="my-2 flex flex-col space-y-1">
                      {isEmpty(components) ? (
                        <div className="pt-6">
                          <HelperMessage
                            className="h-24"
                            iconClassName="h-8 w-8 text-gray-800"
                            message="No collection found. Collection help you to organize your data. Create a new one and share with your team."
                          />
                        </div>
                      ) : (
                        <>
                          {keys.map((key) => {
                            const components = groupedComponents[key];
                            const isGrouped = keys.length > 1;

                            return (
                              <div className="w-full" key={key}>
                                {isGrouped && (
                                  <div className="w-full bg-gray-100 px-2 py-2 text-xs capitalize text-gray-700">
                                    {t(key)}
                                  </div>
                                )}
                                {components.map((component: any) => {
                                  const isSelected = Boolean(
                                    selectedComponentIds?.includes(
                                      component.id,
                                    ),
                                  );

                                  return (
                                    <button
                                      key={component.id}
                                      className={clsx(
                                        "flex w-full items-center justify-between px-2 py-1.5 text-left text-xs hover:bg-gray-900 hover:text-white",
                                        {
                                          "bg-white text-gray-800": !isSelected,
                                          "bg-gray-900 text-white": isSelected,
                                          "pl-4": isGrouped,
                                        },
                                      )}
                                      onClick={handleGrouping(
                                        component,
                                        isSelected,
                                        close,
                                      )}
                                    >
                                      <span>{component.properties?.label}</span>
                                      {isSelected && (
                                        <XIcon className="h-4 w-4" />
                                      )}
                                    </button>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
