import {
  ArrowUturnLeftIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";
import {
  Control,
  UseFieldArrayAppend,
  useFieldArray,
  useFormState,
} from "react-hook-form";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import { EmptyIndicator } from "@amenda-components/Shared";
import { ErrorMessage } from "./ErrorMessage";
import { IconButton } from "@amenda-components/App";
import { OperationsMenu } from "@amenda-components/Dashboard";
import { PhoneInputBase } from "./PhoneInputBase";
import clsx from "clsx";
import { formatPhoneNumberIntl } from "react-phone-number-input/input";
import { getMailOrPhoneRef } from "./common";
import isEmpty from "lodash/isEmpty";
import { isValidPhoneNumber } from "libphonenumber-js";
import { labelledInputSchema } from "@amenda-constants";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  label: string;
  values?: {
    label: string;
    value: string;
  }[];
  type: "email" | "phone";
  isReadOnly?: boolean;
  isDisabled?: boolean;
  error?: string;
  control?: Control<any>;
  isFullWidth?: boolean;
  options: any[];
  onBlur?: () => void;
}

interface UpsertCardProps {
  id: string;
  type: "email" | "phone";
  isEditable?: boolean;
  value?: string;
  label?: string;
  options: any[];
  append: UseFieldArrayAppend<any>;
  update?: (value: any) => void;
  onBlur?: () => void;
  remove?: () => void;
}

interface InputProps {
  id: string;
  value?: string;
  autoFocus?: boolean;
  className?: string;
  onChange: (value: string) => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const ReadOnlyLabelledInputCard: FC<{
  label: string;
  value: string;
  type: "email" | "phone";
}> = ({ value, label, type }) => {
  const { t } = useTranslation();

  if (!value) {
    return null;
  }
  return (
    <div className="flex w-full flex-col">
      <a href={getMailOrPhoneRef(type, value)}>
        <div className="flex min-h-16 shrink-0 flex-row items-center justify-between border bg-white px-3 py-2">
          <div className="flex flex-col items-start">
            <div className="text-xs text-gray-500">{t(label)}</div>
            <div className="text-md text-[#2286e0]">
              {type === "phone" && isValidPhoneNumber(value)
                ? formatPhoneNumberIntl(value)
                : value}
            </div>
          </div>
          {type === "email" ? (
            <EnvelopeIcon className="h-5 w-5 text-[#2286e0]" />
          ) : (
            <PhoneIcon className="h-5 w-5 text-[#2286e0]" />
          )}
        </div>
      </a>
    </div>
  );
};

const ReadOnlyCards: FC<
  Pick<Props, "values" | "isDisabled" | "isReadOnly" | "type">
> = ({ type, isReadOnly, isDisabled, values = [] }) => {
  if (!isReadOnly && !isDisabled) {
    return null;
  }
  if (isEmpty(values)) {
    return <EmptyIndicator />;
  }
  return (
    <>
      {values.map(({ label, value }) => (
        <ReadOnlyLabelledInputCard
          key={`${value}_${label}`}
          type={type}
          label={label}
          value={value}
        />
      ))}
    </>
  );
};

const Input: FC<InputProps> = ({
  id,
  className,
  value = "",
  autoFocus = false,
  onChange,
  onKeyUp,
}) => {
  const { t } = useTranslation();

  const onInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  return (
    <input
      id={id}
      autoFocus={autoFocus}
      autoComplete="off"
      placeholder={t("Enter value and hit save")}
      className={className}
      value={value}
      onChange={onInputChange}
      onKeyUp={onKeyUp}
    />
  );
};

const inputClassName =
  "w-64 text-md text-gray-900 placeholder:text-sm placeholder:text-gray-400 p-0 border-gray-200 border-0 border-b-[1px] focus:border-gray-900 focus:outline-none focus:ring-0";

const UpsertCard: FC<UpsertCardProps> = ({
  id,
  type,
  options,
  isEditable,
  label: selectedLabel,
  value: selectedValue,
  append,
  onBlur,
  remove,
  update = () => {},
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<{
    label?: any;
    value?: string;
  }>({});
  const [errors, setErrors] = useState<string>();
  const [isEditing, setIsEditing] = useState(false);

  const onChangeOption = (option: any) => {
    setValues({
      ...values,
      label: option,
    });
  };

  const hasValue = (values: any) => values.value && values.value.length > 4;

  const onSaveWrapper = (errors?: any[]) => {
    if (!errors || errors.length <= 0) {
      const { value, label } = values;
      const handleSave = isEditing ? update : append;

      handleSave({
        value,
        label: label.value,
      });
      setValues({
        label: undefined,
        value: "",
      });
      onBlur?.();
      setIsEditing(false);
    }
  };

  const setErrorWrapper = (errors?: any[]) => {
    if (errors) {
      setErrors(errors[0]);
    }
  };

  const onSave = async () => {
    const errors = await labelledInputSchema(type, values);

    setErrorWrapper(errors);
    onSaveWrapper(errors);
  };

  const onEdit = () => {
    const label = options.find((option) => option.value === selectedLabel);

    setIsEditing(!isEditing);
    setValues({ label, value: selectedValue });
  };

  const onRemove = () => {
    remove?.();
    onBlur?.();
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      onSave();
    }
  };

  const onChange = (value: string) => setValues({ ...values, value });

  useEffect(() => {
    const handleAsync = async () => {
      if (hasValue(values)) {
        const errors = (await labelledInputSchema(type, values)) || [];
        setErrorWrapper(errors);
      }
    };

    handleAsync();
  }, [values, type]);

  return (
    <div
      className="labelledInput hover:group-hover group flex w-full flex-col"
      onClick={() => !isEditing && isEditable && onEdit()}
    >
      <div className="min-h-[5.5rem] shrink-0 border border-gray-300 bg-white px-3 py-2 group-focus-within:border-gray-500">
        <div className="block flex-row items-center justify-between sm:flex">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col items-start">
              <div className="w-full pb-3">
                {isEditable && !isEditing ? (
                  <div className="text-xs text-gray-500">
                    {selectedLabel && t(selectedLabel)}
                  </div>
                ) : (
                  <OperationsMenu
                    label="Select label"
                    menuItemsClassName="origin-top-left left-0 fixed"
                    options={options}
                    selectedOption={values.label?.value}
                    labelClassName="text-xs text-gray-500"
                    onChange={onChangeOption}
                  />
                )}
              </div>
              {isEditable && !isEditing ? (
                <div className="text-md mb-[7px] text-[#2286e0]">
                  {selectedValue && (
                    <a
                      href={
                        selectedValue && getMailOrPhoneRef(type, selectedValue)
                      }
                    >
                      {type === "phone"
                        ? formatPhoneNumberIntl(selectedValue)
                        : selectedValue}
                    </a>
                  )}
                </div>
              ) : type === "phone" ? (
                <PhoneInputBase
                  id={id}
                  value={values.value}
                  autoFocus={false}
                  className="w-64"
                  onChange={onChange}
                />
              ) : (
                <Input
                  id={id}
                  value={values.value}
                  autoFocus={false}
                  className={inputClassName}
                  onChange={onChange}
                  onKeyUp={onKeyUp}
                />
              )}
            </div>
          </div>
          <div className="h-7 items-center justify-between">
            {isEditable && !isEditing ? (
              <div className="flex justify-end space-x-1">
                <IconButton
                  label="Delete item"
                  iconSize={1}
                  className="mr-4 text-gray-600 md:mr-0"
                  Icon={TrashIcon}
                  onClick={onRemove}
                />
                <IconButton
                  label="Edit item"
                  iconSize={1}
                  className="text-gray-600"
                  Icon={isEditing ? ArrowUturnLeftIcon : PencilIcon}
                  onClick={onEdit}
                />
              </div>
            ) : (
              <div className="flex justify-end space-x-1">
                {values.label || hasValue(values) ? (
                  <>
                    <IconButton
                      label="Edit item"
                      Icon={XMarkIcon}
                      onClick={onEdit}
                    />
                    <IconButton
                      label="Add item"
                      Icon={CheckIcon}
                      className="text-green-500"
                      onClick={onSave}
                    />
                  </>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <ErrorMessage id={id} error={errors} className="text-xs" />
    </div>
  );
};

const LabelledInputCards: FC<
  Pick<
    Props,
    | "control"
    | "id"
    | "type"
    | "isDisabled"
    | "isReadOnly"
    | "onBlur"
    | "options"
  >
> = ({ id, control, isDisabled, isReadOnly, ...rest }) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: id,
  });

  if (isReadOnly || isDisabled) {
    return null;
  }
  return (
    <>
      {fields.map((field, index) => {
        const { id, value, label } = field as any;
        return (
          <UpsertCard
            key={id}
            id={id}
            label={label}
            value={value}
            isEditable={true}
            append={append}
            update={(value: any) => update(index, value)}
            remove={() => remove(index)}
            {...rest}
          />
        );
      })}
      <UpsertCard id={id} append={append} {...rest} />
    </>
  );
};

const InputErrorMessage: FC<{
  id: string;
  control: Control<any>;
}> = ({ control, id }) => {
  const { errors } = useFormState({
    control,
    name: id,
  });

  const error = errors[id];

  return (
    <ErrorMessage id={id} error={error?.message as any} className="text-xs" />
  );
};

export const LabelledInput: FC<Props> = ({
  id,
  label,
  values,
  control,
  isReadOnly = false,
  isFullWidth = false,
  ...rest
}) => {
  const { t } = useTranslation();
  //temporary fix
  const allValues = Array.isArray(values) ? values : [];

  return (
    <div className="w-full">
      <label htmlFor={id} className="amenda-component-label">
        {t(label)}
      </label>
      <div
        className={clsx("mt-1 grid w-full grid-cols-1 gap-3", {
          "xl:grid-cols-2": !isFullWidth,
        })}
      >
        {control && (
          <LabelledInputCards
            id={id}
            control={control}
            isReadOnly={isReadOnly}
            {...rest}
          />
        )}
        <ReadOnlyCards isReadOnly={isReadOnly} values={allValues} {...rest} />
      </div>
      {control && <InputErrorMessage id={id} control={control} />}
    </div>
  );
};
