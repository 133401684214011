import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  CheckIcon,
  CloudIcon,
  GripVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
} from "lucide-react";
import { Control, useFieldArray } from "react-hook-form";
import { FC, KeyboardEvent, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "./FormBuilderDndComponents";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { FormBuilderNestedOptions } from "./FormBuilderNestedOptions";
import { FormComponentTypes } from "@amenda-constants";
import { IconButtonBase } from "@amenda-components/App";
import { TextField } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { inputFieldTheme } from "@amenda-styles/theme";
import isEmpty from "lodash/isEmpty";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { sanitizeData } from "@amenda-utils";
import { snakeCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useUpsertKeywords } from "@amenda-domains/mutations";

interface Props {
  id: string;
  control: Control<any>;
  component: any;
  isLoading: boolean;
  keywords: any[];
  updateKeywordsState: (keyword: any, action?: "update" | "delete") => void;
}

type OptionsWrapperProps = Omit<
  Props,
  "keywords" | "component" | "handleUpdateKeywords"
> & {
  enableOptionDescriptions?: boolean;
  label?: string;
};

interface DropdownItemProps {
  isUpdating?: boolean;
  isSelected?: boolean;
  label: string;
  description?: string;
  enableOptionDescriptions?: boolean;
  isDraggable?: boolean;
  isDragging?: boolean;
  isExpandable?: boolean;
  hasExpanded?: boolean;
  level?: number;
  handleExpand?: () => void;
  handleDelete?: () => void;
  handleSelection?: () => void;
  handleUpdate?: (text: string, description?: string) => void;
}

interface AddDropdownItemProps {
  placeHolder?: string;
  isCreating?: boolean;
  handleAdd: (text: string) => void;
}

const AddDropdownItem: FC<AddDropdownItemProps> = ({
  isCreating,
  placeHolder = "Add option",
  handleAdd,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>("");

  const onClick = async () => {
    if (text) {
      await handleAdd(text);
      setText("");
    }
  };

  return (
    <div className="absolute bottom-0 left-0 w-full py-2">
      <TextField
        id="add-option"
        value={text}
        placeholder={t(placeHolder)}
        onChange={setText}
        withEndButton
        endButtonText="Add"
        EndButtonIcon={PlusIcon}
        endButtonLoading={isCreating}
        onClick={onClick}
      />
    </div>
  );
};

const DropdownItem: FC<DropdownItemProps> = ({
  isUpdating,
  isSelected,
  label,
  isDraggable,
  isDragging,
  description,
  level = 0,
  handleDelete,
  handleUpdate,
  handleSelection,
  enableOptionDescriptions,
}) => {
  const { inputCss, inputWrapperCss } = inputFieldTheme();
  const [text, setText] = useState(label);
  const [subText, setSubText] = useState<string | undefined>(description);

  const onChange = (e: any) => setText(e.target.value);
  const onSubTextChange = (e: any) => setSubText(e.target.value);

  const handleEdit = async () => {
    if (text) {
      await handleUpdate?.(text, subText);
      handleSelection?.();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault();
      handleEdit();
    }
  };

  return (
    <div
      className={clsx(
        "group/option flex w-full cursor-pointer items-center px-2 text-sm outline-none",
        {
          "bg-gray-900 text-white": Boolean(isSelected),
          "text-gray-800 hover:bg-gray-600 hover:text-white":
            !Boolean(isSelected),
          invisible: isDragging,
        },
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex w-1/2 flex-col space-y-0.5 py-0.5">
          <div className="flex w-full items-center">
            {isDraggable && !isSelected && (
              <div>
                <IconButtonBase
                  size="sm"
                  className="mr-1 cursor-grab px-0.5 py-1"
                >
                  <GripVerticalIcon className="h-5 w-5 stroke-[2]" />
                </IconButtonBase>
              </div>
            )}
            {isSelected ? (
              <div
                className={inputWrapperCss({
                  size: "sm",
                  class: "flex-row border-none",
                })}
              >
                <input
                  className={inputCss({
                    class: "text-gray-900",
                  })}
                  value={text}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            ) : (
              <span
                className="mr-1 truncate"
                style={{
                  paddingLeft: `${20 * level}px`,
                }}
              >
                {label}
              </span>
            )}
          </div>
          {Boolean(enableOptionDescriptions) && (
            <>
              {isSelected ? (
                <input
                  className="w-full bg-white px-1 text-gray-900 focus:outline-none"
                  value={subText}
                  onChange={onSubTextChange}
                  onKeyDown={handleKeyDown}
                />
              ) : (
                <span className="mr-1 truncate text-xs italic">
                  {description}
                </span>
              )}
            </>
          )}
        </div>
        <div className="flex items-center space-x-1">
          <IconButtonBase
            variant="clean"
            className={clsx("p-1", {
              "bg-gray-900 text-white": Boolean(isSelected),
              "invisible group-hover/option:visible group-hover/option:bg-gray-600 group-hover/option:text-white":
                !Boolean(isSelected),
            })}
            onClick={!isSelected ? handleSelection : handleEdit}
          >
            {isUpdating ? (
              <CloudIcon className="h-4 w-4 animate-pulse" />
            ) : isSelected ? (
              <CheckIcon className="h-4 w-4" />
            ) : (
              <PencilIcon className="h-4 w-4" />
            )}
          </IconButtonBase>
          {isSelected && !isUpdating && (
            <IconButtonBase
              variant="clean"
              className={clsx("p-1", {
                "bg-gray-900 text-white": Boolean(isSelected),
                "invisible group-hover/option:visible group-hover/option:bg-gray-600 group-hover/option:text-white":
                  !Boolean(isSelected),
              })}
              onClick={() => {
                handleSelection?.();
                setText(label);
              }}
            >
              <XIcon className="h-4 w-4" />
            </IconButtonBase>
          )}
          {!isSelected && (
            <IconButtonBase
              variant="clean"
              className={clsx("p-1", {
                "bg-gray-900 text-white": Boolean(isSelected),
                "invisible group-hover/option:visible group-hover/option:bg-gray-600 group-hover/option:text-white":
                  !Boolean(isSelected),
              })}
              onClick={handleDelete}
            >
              <TrashIcon className="h-4 w-4" />
            </IconButtonBase>
          )}
        </div>
      </div>
    </div>
  );
};

const OptionsWrapper: FC<OptionsWrapperProps> = ({
  id,
  control,
  enableOptionDescriptions,
  label = "Options",
}) => {
  const { t } = useTranslation();
  const { fields, remove, update, append, replace } = useFieldArray<
    any,
    any,
    any
  >({
    control,
    name: id,
    keyName: "arrId",
  });
  const [selectedOption, setSelectedOption] = useState<Record<string, any>>();
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const activeField = fields.find((field) => field.value === activeId);

  const handleSelection = (field: any) =>
    setSelectedOption((prev) => {
      if (prev?.value === field.value) {
        return undefined;
      }
      return field;
    });

  const handleAdd = (text: string) => {
    append({
      label: text,
      value: snakeCase(text),
    });
  };

  const shuffleParents = (active: Active, over: Over) => {
    const fieldIds = fields.map((f) => f.value);
    const oldIndex = fieldIds.indexOf(active.id as string);
    const newIndex = fieldIds.indexOf(over.id as string);
    const updatedFieldIds = arrayMove(fieldIds, oldIndex, newIndex);
    const values = updatedFieldIds.map((id) => {
      const value = fields.find((f) => f.value === id);
      return value;
    });

    replace(values.map(({ arrId, ...rest }: any) => rest));
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    shuffleParents(active, over);
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveId(String(active.id));
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <SortableContext
        id="formBuilderOptions"
        items={fields.map((field) => field.value)}
        strategy={verticalListSortingStrategy}
      >
        <div className="relative w-full bg-white pb-14">
          <div className="max-h-96 overflow-y-auto overscroll-contain">
            <label className="amenda-component-label">{t(label)}</label>
            <FormBuilderSortDroppable>
              {fields.map((field, i) => (
                <>
                  <FormBuilderSortableItem key={field.value} id={field.value}>
                    {(isDragging) => (
                      <DropdownItem
                        key={field.value}
                        isDragging={isDragging}
                        isDraggable={true}
                        enableOptionDescriptions={enableOptionDescriptions}
                        isSelected={selectedOption?.value === field.value}
                        label={field.label}
                        description={field?.description}
                        handleDelete={() => remove(i)}
                        handleSelection={() => handleSelection(field)}
                        handleUpdate={(label, description) =>
                          update(
                            i,
                            sanitizeData({
                              label,
                              description,
                              value: field.value,
                            }),
                          )
                        }
                      />
                    )}
                  </FormBuilderSortableItem>
                </>
              ))}
            </FormBuilderSortDroppable>
            <AddDropdownItem handleAdd={handleAdd} />
          </div>
        </div>
      </SortableContext>
      <>
        {createPortal(
          <DragOverlay modifiers={[restrictToVerticalAxis]}>
            {activeField && (
              <FormBuilderDragOverlayItem>
                <div className="flex w-full items-center">
                  <div>
                    <IconButtonBase
                      size="sm"
                      className="cursor-grab px-0.5 py-1"
                    >
                      <GripVerticalIcon className="h-5 w-5 stroke-[2]" />
                    </IconButtonBase>
                  </div>
                  <span className="ml-2 truncate">{activeField.label}</span>
                </div>
              </FormBuilderDragOverlayItem>
            )}
          </DragOverlay>,
          document.body,
        )}
      </>
    </DndContext>
  );
};

const KeywordWrapper: FC<Props> = ({
  component,
  isLoading,
  keywords,
  updateKeywordsState,
}) => {
  const { t } = useTranslation();
  const [selectedKeyword, setSelectedKeyword] = useState<Record<string, any>>();
  const { upsertKeywords, loading } = useUpsertKeywords();
  const [isDeleting, setIsDeleting] = useState(false);

  const title = t("Tags") + (Boolean(isLoading) ? "..." : "");
  const isUpdating =
    (selectedKeyword?.id && loading) || (isDeleting && loading);
  const isCreating = !selectedKeyword?.id && !isDeleting && loading;

  const handleSelection = (keyword: any) =>
    setSelectedKeyword((prev) => {
      if (prev?.id === keyword.id) {
        return undefined;
      }
      return keyword;
    });

  const handleUpdate = async (text: string, keyword: any) => {
    if (text && keyword) {
      await upsertKeywords({
        id: keyword.id,
        name: text,
        componentId: keyword.componentId,
        callback: (keyword) => updateKeywordsState(keyword, "update"),
      });
    }
  };

  const handleDelete = async (keyword: any) => {
    if (keyword) {
      setIsDeleting(true);
      await upsertKeywords({
        id: keyword.id,
        name: keyword.name,
        componentId: keyword.componentId,
        isDeleted: true,
        callback: (keyword) => updateKeywordsState(keyword, "delete"),
      });
      setIsDeleting(false);
    }
  };

  const handleAdd = async (text: string) => {
    if (text) {
      await upsertKeywords({
        name: text,
        componentId: component.id,
        callback: (keyword) => updateKeywordsState(keyword),
      });
    }
  };

  return (
    <div className="relative w-full bg-white pb-14">
      <div className="max-h-96 overflow-y-auto overscroll-contain">
        <label className="amenda-component-label">{title}</label>
        {keywords.map((keyword) => (
          <DropdownItem
            key={keyword.id}
            isUpdating={isUpdating}
            isSelected={
              selectedKeyword?.id && selectedKeyword.id === keyword.id
            }
            label={keyword.name}
            handleSelection={() => handleSelection(keyword)}
            handleUpdate={(text) => handleUpdate(text, keyword)}
            handleDelete={() => handleDelete(keyword)}
          />
        ))}
      </div>
      <AddDropdownItem
        isCreating={isCreating}
        placeHolder="Add keyword"
        handleAdd={handleAdd}
      />
    </div>
  );
};

export const FormBuilderComponentOptions: FC<Props> = ({
  component,
  ...rest
}) => {
  if (!component?.component) return null;

  switch (component.component) {
    case FormComponentTypes.Keyword:
      return <KeywordWrapper component={component} {...rest} />;
    case FormComponentTypes.Select:
    case FormComponentTypes.Badges:
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.LabelledInput:
    case FormComponentTypes.RadioButton:
      if (Boolean(component?.properties?.isNested)) {
        return <FormBuilderNestedOptions component={component} {...rest} />;
      }
      return <OptionsWrapper {...rest} />;
    case FormComponentTypes.Checkbox:
      return <OptionsWrapper enableOptionDescriptions={true} {...rest} />;
    case FormComponentTypes.SearchAndSelect:
      if (!isEmpty(component.properties?.roles)) {
        return <OptionsWrapper label="Roles" {...rest} id="roles" />;
      }
      return null;
    case FormComponentTypes.LabelledContactInputs:
      return (
        <div className="flex w-full flex-col space-y-2 bg-white pb-14">
          <KeywordWrapper component={component} {...rest} />
          <OptionsWrapper label="Roles" {...rest} id="roles" />
        </div>
      );
    default:
      return null;
  }
};
