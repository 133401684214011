import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Control, UseFormSetValue } from "react-hook-form";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IconButton, ProfilerWrapper, Tooltip } from "@amenda-components/App";
import {
  expandAllComponentsInGroup,
  groupComponentsById,
  toggleComponentGroups,
  unfoldComponents,
  useSearchComponents,
} from "./common";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { PaddingRow } from "./PaddingRow";
import clsx from "clsx";
import { debounce } from "lodash";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

interface ComponentsTreeViewRowTitleProps {
  children: ReactNode;
  isOpen?: boolean;
  hasIcon?: boolean;
  level?: number;
  onClick?: () => void;
}

interface ComponentTreeColumnProps {
  id: string;
  label?: string;
  className?: string;
}

interface SharedComponentsTreeProps {
  readOnly: boolean;
  disabled?: boolean;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
}

export interface ComponentsTreeViewRowProps extends SharedComponentsTreeProps {
  component: any;
  searchTerm: string;
  column: ComponentTreeColumnProps;
  globalData: Record<string, any>;
  componentsById: Record<string, any>;
  isOpen: boolean;
  level?: number;
  expandAllComponentsInGroup: (components: any[]) => void;
  closeAllComponentsInGroup: () => void;
}

interface Props extends SharedComponentsTreeProps {
  label?: string;
  components: any[];
  className?: string;
  columnClassName?: string;
  rowClassName?: string;
  searchPlaceholder?: string;
  columns: ComponentTreeColumnProps[];
  maxRowHeightInPx?: number;
  globalData?: Record<string, any>;
  HeaderComponent?: FC<{ column: ComponentTreeColumnProps }>;
  RowSelector?: FC<ComponentsTreeViewRowProps>;
  RowBody: FC<ComponentsTreeViewRowProps>;
  showTooltip?: boolean;
  headerChildren?: ReactNode;
}

const RowTitle: FC<ComponentsTreeViewRowTitleProps> = ({
  onClick,
  children,
  isOpen = false,
  hasIcon = true,
  level = 0,
}) => {
  const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon;

  return (
    <div
      className="amenda-w-inherit"
      style={{
        paddingLeft: 10 * level,
      }}
    >
      <div
        className={clsx("flex w-full items-center space-x-2", {
          "pl-6": !hasIcon,
        })}
      >
        {hasIcon && (
          <button
            className="p-0.5 text-gray-900 hover:bg-gray-900 hover:text-white"
            type="button"
            onClick={onClick}
          >
            <Icon
              className="h-4 w-4"
              onClick={onClick}
              aria-label={`${isOpen ? "close" : "expand"} row`}
            />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export const ComponentsTreeView: FC<Props> = ({
  label,
  columns,
  components,
  className,
  searchPlaceholder,
  HeaderComponent,
  RowSelector,
  RowBody,
  headerChildren,
  globalData = {},
  readOnly = false,
  showTooltip = false,
  columnClassName = "min-w-[10rem]",
  rowClassName = "h-11",
  maxRowHeightInPx = 34,
  ...rest
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const visibleComponents = useAppStore((state) => state.visibleComponents);
  const expandedComponentGroup = useAppStore(
    (state) => state.expandedComponentGroup,
  );
  const virtualizer = useVirtualizer({
    overscan: 10,
    count: visibleComponents.length,
    estimateSize: () => maxRowHeightInPx,
    getScrollElement: () => containerRef.current,
  });
  const setExpandedComponentGroup = useAppStore(
    (state) => state.setExpandedComponentGroup,
  );
  const setVisibleComponents = useAppStore(
    (state) => state.setVisibleComponents,
  );

  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);

  const componentsById = groupComponentsById(components, {});
  const allComponents = unfoldComponents(components, []);
  const searchComponents = useSearchComponents(allComponents, componentsById);

  const handleToggle = (index: number, component: any) => {
    const { components, updateExpandedComponentGroups } = toggleComponentGroups(
      {
        index,
        component,
        searchTerm,
        searchComponents,
        visibleComponents,
        expandedComponentGroup,
      },
    );
    setExpandedComponentGroup({
      ...updateExpandedComponentGroups,
    });
    setVisibleComponents([...components]);
  };

  const handleExpandAll =
    (index: number, component: any) => (components: any[]) => {
      // don't expand all when searching, leads to duplicate components
      if (!searchTerm) {
        const {
          components: expandedComponents,
          updateExpandedComponentGroups,
        } = expandAllComponentsInGroup({
          index,
          component,
          components,
          visibleComponents,
          expandedComponentGroup,
        });

        setExpandedComponentGroup({
          ...updateExpandedComponentGroups,
        });
        setVisibleComponents([...expandedComponents]);
      }
    };

  const handleTreeComponents = useCallback(() => {
    setVisibleComponents(components);
    setExpandedComponentGroup({});
  }, [setVisibleComponents, setExpandedComponentGroup, components]);

  const handleClick = () => {
    setSearchTerm("");
    handleTreeComponents();
    setShowSearch(false);
  };

  const debouncedFn = useMemo(
    () =>
      debounce((value) => {
        if (value) {
          const { expandedComponentGroup, visibleComponents } =
            searchComponents(value);

          setVisibleComponents(visibleComponents);
          setExpandedComponentGroup(expandedComponentGroup);
        } else {
          handleTreeComponents();
        }
      }, 500),
    [
      searchComponents,
      setVisibleComponents,
      setExpandedComponentGroup,
      handleTreeComponents,
    ],
  );

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    debouncedFn(value);
  };

  useEffect(() => {
    handleTreeComponents();
  }, [handleTreeComponents]);

  return (
    <ProfilerWrapper id="ComponentsTreeView">
      {label && <h3 className="amenda-form-heading pt-5">{t(label)}</h3>}
      {headerChildren}
      <div
        ref={containerRef}
        className={clsx("bg-inherit overflow-x-auto", className)}
      >
        <div className="table w-full table-fixed divide-y">
          <div className="table-row-group">
            <div className="table-row h-11">
              <div className="sticky left-0 top-0 z-40 table-cell h-full w-[26rem] border-b bg-white align-middle">
                <div className="mt-4 h-10">
                  {showSearch ? (
                    <MiniSearchField
                      className="w-60 pl-2"
                      placeholder={searchPlaceholder}
                      value={searchTerm}
                      onChange={handleSearch}
                      onClear={handleClick}
                    />
                  ) : (
                    <IconButton
                      iconSize={1}
                      Icon={MagnifyingGlassIcon}
                      label={t("Open Search")}
                      onClick={() => setShowSearch(true)}
                    />
                  )}
                </div>
              </div>
              {columns.map((col) => (
                <div
                  key={col.id}
                  className={clsx(
                    "sticky top-0 z-30 table-cell h-full border-b bg-white px-2 text-end align-middle",
                    col.className,
                    {
                      [columnClassName]: !col.className,
                    },
                  )}
                >
                  {col.label && (
                    <span className="amenda-component-label py-1">
                      {t(col.label)}
                    </span>
                  )}
                  {HeaderComponent && <HeaderComponent column={col} />}
                </div>
              ))}
            </div>
            <div className="table-row h-2" />
            <PaddingRow padding={paddingTop} />
            {virtualRows.map(({ index }) => {
              const component = visibleComponents[index];
              const level = component?.level || 0;
              const isOpen = Boolean(expandedComponentGroup[component.id]);
              const label = component.properties?.label || "Missing Label";

              const handleExpandComponent = () => {
                handleToggle(index, component);
              };

              const handleClose = () => {
                if (isOpen) {
                  handleToggle(index, component);
                }
              };

              return (
                <div key={index} className={clsx("table-row", rowClassName)}>
                  <div
                    className={clsx(
                      "bg-inherit sticky left-0 z-30 table-cell h-full w-[26rem] py-1 align-middle",
                      columnClassName,
                    )}
                  >
                    <div className="flex w-full">
                      {RowSelector && (
                        <RowSelector
                          level={level}
                          searchTerm={searchTerm}
                          isOpen={isOpen}
                          component={component}
                          column={columns[index - 1]}
                          readOnly={readOnly}
                          globalData={globalData}
                          componentsById={componentsById}
                          closeAllComponentsInGroup={handleClose}
                          expandAllComponentsInGroup={handleExpandAll(
                            index,
                            component,
                          )}
                          {...rest}
                        />
                      )}
                      <RowTitle
                        level={level}
                        isOpen={isOpen}
                        onClick={handleExpandComponent}
                        hasIcon={Boolean(component?.components)}
                      >
                        <Tooltip
                          key={component.id}
                          className="w-full overflow-hidden truncate pr-4 text-sm"
                          id={component.id}
                          message={label}
                          positionStrategy="fixed"
                          position="right"
                          hidden={!showTooltip}
                        >
                          {label}
                        </Tooltip>
                      </RowTitle>
                    </div>
                  </div>
                  {columns.map((col) => (
                    <div
                      key={col.id}
                      className={clsx(
                        "bg-inherit cursor-arrow table-cell h-full px-2 align-middle",
                        col.className,
                        {
                          [columnClassName]: !col.className,
                        },
                      )}
                    >
                      <RowBody
                        level={level}
                        isOpen={isOpen}
                        searchTerm={searchTerm}
                        component={component}
                        column={col}
                        readOnly={readOnly}
                        globalData={globalData}
                        componentsById={componentsById}
                        closeAllComponentsInGroup={handleClose}
                        expandAllComponentsInGroup={handleExpandAll(
                          index,
                          component,
                        )}
                        {...rest}
                      />
                    </div>
                  ))}
                </div>
              );
            })}
            <PaddingRow padding={paddingBottom} />
          </div>
        </div>
      </div>
    </ProfilerWrapper>
  );
};
