import { AvailableImageVariants, File } from "@amenda-types";
import {
  Bars3BottomLeftIcon,
  MinusIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import {
  FC,
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  useEffect,
} from "react";
import { HoverSelector, IconButton, Image } from "@amenda-components/App";
import {
  LockClosedIcon,
  LockOpenIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { isArray, isEmpty } from "lodash";
import { parseComponentHeightAndWidth, processMagicLinks } from "@amenda-utils";

import { CropImage } from "@amenda-components/FileUploads";
import { FormComponentProps } from "@amenda-types";
import clsx from "clsx";
import { formatTemplateValues } from "@amenda-components/Projects/common";
import { useTemplatesStore } from "@amenda-domains/mutations";

export interface ToolBoxItemProps {
  label: string;
  Icon: FC<{ className: string }> | ForwardRefExoticComponent<any>;
  componentName: string;
  Component: FC<any>;
  defaultProps?: any;
}

export const GalleryImage: FC<{
  file: any;
  handleSelectFile: (file: any) => void;
  isSelected?: boolean;
}> = ({ file, handleSelectFile, isSelected }) => {
  return (
    <HoverSelector
      className="cursor-pointer"
      canSelect={true}
      isSelected={isSelected}
      handleClick={() => handleSelectFile(file)}
    >
      <Image
        url={file.url}
        magicSize="thumbnail"
        size="pb-10/12"
        variant={AvailableImageVariants.contain}
      />
    </HoverSelector>
  );
};

export const Gallery: FC<{
  handleSelectFile: (file: any) => void;
  selectedFile: any;
  global: any;
}> = ({ handleSelectFile, selectedFile, global }) => {
  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 p-3">
      {global.attachments?.map?.((file: File) => (
        <GalleryImage
          key={file.name}
          file={{ ...file, current: selectedFile?.name === file.name }}
          handleSelectFile={handleSelectFile}
        />
      ))}
    </ul>
  );
};

interface TitleProps {
  alignContents?: "right" | "left" | "center" | "justify";
  className?: string;
  content: string;
  fontSize?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl";
}

const Title: FC<TitleProps> = ({
  alignContents = "left",
  className = "",
  content,
  fontSize = "2xl",
}) => {
  const cssClassName = `text-${alignContents} text-${fontSize}`;

  return (
    <h1
      className={clsx("w-full tracking-tight", className, cssClassName, {
        "text-gray-900": isEmpty(className),
      })}
    >
      {content}
    </h1>
  );
};

const Photo = ({
  alt,
  containerHeight = "20",
  className = "",
  height,
  src,
  width,
  global,
  ...props
}: any) => {
  const setSelectedPhoto = useTemplatesStore((state) => state.setSelectedPhoto);
  const templateImage = useTemplatesStore((state) => state.templateImage);
  const photoSlots = useTemplatesStore((state) => state.photoSlots);
  const selectedPhoto = useTemplatesStore((state) => state.selectedPhoto);
  const currentTemplateData = useTemplatesStore(
    (state) => state.selectedTemplateData,
  );
  const updateTemplateImage = useTemplatesStore(
    (state) => state.updateTemplateImage,
  );
  const togglePhotoSlots = useTemplatesStore((state) => state.togglePhotoSlots);

  const { componentsById } = global;
  const parentId = componentsById[props.id]?.parentId;
  const parentStyle = componentsById[parentId]?.properties?.style;
  const imageSrc = templateImage[props.id] ?? "";
  const isPhotoLocked = photoSlots[props.id] ?? true;

  useEffect(() => {
    if (!isEmpty(selectedPhoto) && !isPhotoLocked) {
      updateTemplateImage(props.id, selectedPhoto.url);
    }
  }, [selectedPhoto, props.id, isPhotoLocked, updateTemplateImage]);

  useEffect(() => {
    if (isPhotoLocked) {
      setSelectedPhoto({});
    }
  }, [isPhotoLocked, setSelectedPhoto]);

  const toggleLockButton = () => {
    togglePhotoSlots(props.id);
    if (photoSlots[props.id]) {
      setSelectedPhoto({});
    }
  };

  const handleDelete = () => {
    updateTemplateImage(props.id, "");
    setSelectedPhoto({});
  };

  const handleAutoSave = ({
    croppedArea,
    croppedAreaPixels,
    zoom,
    crop,
  }: any) => {
    const values = {
      ...currentTemplateData?.values,
      ...formatTemplateValues({
        [props.id]: {
          croppedArea,
          croppedAreaPixels,
          imageSrc,
          zoom,
          crop,
        },
      }),
    };
    global?.handleAutoSave?.({ ...(global?.templateData || {}), ...values });
  };

  const getObjectFit = () => {
    return width > height ? "horizontal-cover" : "vertical-cover";
  };

  return (
    <div
      className={clsx(
        "relative h-full w-full overflow-hidden rounded bg-white",
        className,
      )}
    >
      <div className="absolute z-30 m-2 flex justify-between">
        <IconButton
          className="mr-2"
          Icon={isPhotoLocked ? LockClosedIcon : LockOpenIcon}
          label="Edit Photo"
          onClick={toggleLockButton}
          iconSize={4}
        />
        <IconButton
          Icon={TrashIcon}
          label="Edit Photo"
          onClick={handleDelete}
          iconSize={4}
        />
      </div>
      <div className="h-full w-full cursor-pointer">
        <CropImage
          disabled={isPhotoLocked}
          src={
            imageSrc &&
            processMagicLinks(imageSrc, {
              w: 1600,
            })
          }
          fitObject={getObjectFit()}
          mediaClassName="object-contain"
          restrictPosition={false}
          onCropComplete={handleAutoSave}
          onMediaLoaded={({
            setCropSize,
            setAspectRatio,
            setZoom,
            setCrop,
          }) => {
            const { width, height } = parseComponentHeightAndWidth(parentStyle);
            if (height && width) {
              setCropSize({
                width,
                height,
              });
              setAspectRatio(width / height);
              const cropProperties =
                currentTemplateData?.values?.[props.id]?.properties;
              if (cropProperties?.zoom) {
                setZoom(cropProperties.zoom);
              }
              if (cropProperties?.crop) {
                setCrop(cropProperties.crop);
              }
            }
          }}
        />
        {isEmpty(imageSrc) && (
          <div className="flex h-full w-full items-center justify-center bg-blue-50" />
        )}
      </div>
    </div>
  );
};

Photo.craft = {
  props: {
    src: "/images/imagePlaceholder.svg",
    height: 1,
    width: 2,
  },
  rules: {
    canDrop: () => true,
    canDrag: () => true,
    canMoveIn: () => true,
    canMoveOut: () => true,
  },
  related: {},
};

interface ParagraphProps {
  alignContents?: "right" | "left" | "center" | "justify";
  className?: string;
  content: string;
  fontSize?: "xs" | "sm" | "base" | "lg";
}

const Paragraph: FC<ParagraphProps> = ({
  alignContents = "left",
  className = "",
  content,
  fontSize = "sm",
}) => {
  const cssClassName = `text-${alignContents} text-${fontSize}`;

  return (
    <p
      className={clsx("w-full break-words", className, cssClassName, {
        "text-gray-900": isEmpty(className),
      })}
    >
      {content}
    </p>
  );
};

const ParagraphFixedUnits: FC<
  ParagraphProps & {
    style?: any;
  }
> = ({ content, className = "", alignContents = "left", style }) => {
  const alignCss = `text-${alignContents}`;

  return (
    <p
      className={clsx("w-full break-words", alignCss, className, {
        "text-gray-900": isEmpty(className),
      })}
      style={style}
    >
      {content}
    </p>
  );
};

interface LogoProps {
  className: string;
  companyName?: string;
  src: string;
  size?: number;
  square?: boolean;
}

const Logo: FC<LogoProps> = ({
  className = "",
  companyName,
  src,
  size = 8,
  square = false,
}) => {
  const autoWCss = `h-${size} w-auto`;
  const squareCss = `h-${size} max-w-min w-${size}`;
  return (
    <div className="w-full">
      {src ? (
        <img
          className={clsx(className, {
            [autoWCss]: !square,
            [squareCss]: square,
          })}
          src={src}
          alt={companyName}
        />
      ) : (
        <span className={clsx("mb-2 uppercase text-gray-900", className)}>
          {companyName}
        </span>
      )}
    </div>
  );
};

const LogoFixedUnit: FC<
  LogoProps & {
    style?: any;
  }
> = ({ style, className = "", companyName, src }) => {
  return (
    <div className="w-full">
      {src ? (
        <img
          className={clsx(className, `h-auto w-auto`)}
          src={src}
          alt={companyName}
        />
      ) : (
        <span
          className={clsx("uppercase text-gray-900", className)}
          style={style}
        >
          {companyName}
        </span>
      )}
    </div>
  );
};

interface TitleAndDescriptionProps {
  alignContents?: "right" | "left" | "center" | "justify";
  className?: string;
  container: "row" | "column";
  description: string | ReactNode;
  fontSize?: "xs" | "sm" | "base" | "lg";
  title: string;
}

const TitleAndDescription: FC<TitleAndDescriptionProps> = ({
  alignContents = "left",
  className = "",
  container = "row",
  description,
  fontSize = "sm",
  title,
}) => {
  const sizeCss = `text-${fontSize}`;
  const alignCss = `text-${alignContents}`;

  if (isArray(description)) {
    description = description.map((d) => d.label).join(", ");
  }

  return (
    <div
      className={clsx("w-full text-gray-500", sizeCss, {
        [alignCss]: container === "row",
        className,
      })}
    >
      {container === "row" ? (
        <Fragment>
          <span className="mr-1 text-gray-900">{title}</span>
          {description}
        </Fragment>
      ) : (
        <Fragment>
          <dt className="text-gray-900">{title}</dt>
          <dd className="mt-1">{description}</dd>
        </Fragment>
      )}
    </div>
  );
};

const TitleAndDescriptionFixedUnits: FC<
  TitleAndDescriptionProps & {
    style?: any;
  }
> = ({
  title,
  description,
  className = "",
  alignContents = "left",
  container = "row",
  style,
}) => {
  const alignCss = `text-${alignContents}`;

  if (isArray(description)) {
    description = description.map((d) => d.label).join(", ");
  }
  return (
    <div
      className={clsx("w-full text-gray-500", className, {
        [alignCss]: container === "row",
      })}
      style={style}
    >
      {container === "row" ? (
        <Fragment>
          <span className="mr-1 text-gray-900">{title}</span>
          {description}
        </Fragment>
      ) : (
        <Fragment>
          <dt className="text-gray-900">{title}</dt>
          <dd className="mt-1">{description}</dd>
        </Fragment>
      )}
    </div>
  );
};

interface GridContainerProps {
  children: ReactNode;
  gridColumns?: number;
  gap?: number;
  gapXaxis?: number;
  gapYaxis?: number;
}

const GridContainer: FC<GridContainerProps> = ({
  children,
  gridColumns = 3,
  gap = 3,
  gapXaxis,
  gapYaxis,
}) => {
  const gridCss = `grid-cols-${gridColumns}`;
  const gapXCss = `gap-x-${gapXaxis || gap}`;
  const gapYCss = `gap-y-${gapYaxis || gap}`;

  return (
    <div className={clsx("grid h-full w-full", gridCss, gapXCss, gapYCss)}>
      {children}
    </div>
  );
};

const BoxContainer = ({ children, isFormBuilder, config, ...rest }: any) => {
  return <div {...rest}>{children}</div>;
};

export const ToolBoxItems: ToolBoxItemProps[] = [
  {
    label: "Photo",
    Icon: PhotoIcon,
    componentName: "Photo",
    Component: Photo,
  },
  {
    label: "Title",
    Icon: MinusIcon,
    componentName: "Title",
    Component: Title,
    defaultProps: {
      content: "Project Name 1245",
    },
  },
  {
    label: "Long Description",
    Icon: Bars3BottomLeftIcon,
    componentName: "Paragraph",
    Component: Paragraph,
    defaultProps: {
      content: `i am a long description
        and i need some attention
        don't just skip me like that
      `,
    },
  },
];

export const templateComponents = {
  Image: ({ config, global }: FormComponentProps) => {
    return <Photo {...config.properties} global={global} id={config.id} />;
  },
  Logo: ({ config }: FormComponentProps) => {
    return <Logo {...config.properties} />;
  },
  LogoFixedUnit: ({ config }: FormComponentProps) => {
    return <LogoFixedUnit {...config.properties} />;
  },
  TitleAndDescription: ({ config }: FormComponentProps) => {
    return <TitleAndDescription {...config.properties} />;
  },
  TitleAndDescriptionFixedUnits: ({ config }: FormComponentProps) => {
    return <TitleAndDescriptionFixedUnits {...config.properties} />;
  },
  Title: ({ config }: FormComponentProps) => {
    return <Title {...config.properties} />;
  },
  Paragraph: ({ config }: FormComponentProps) => {
    return <Paragraph {...config.properties} />;
  },
  ParagraphFixedUnits: ({ config }: FormComponentProps) => {
    return <ParagraphFixedUnits {...config.properties} />;
  },
};

export const templateLayoutComponents = {
  GridContainer,
  BoxContainer,
};
