import {
  AvailableForms,
  GeneralAccessTypes,
  PermissionComponentKey,
} from "@amenda-constants";
import {
  Button,
  FullScreenModal,
  IconButtonBase,
  Spinner,
  Tooltip,
} from "@amenda-components/App";
import { FC, useEffect, useState } from "react";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { PlusIcon, XIcon } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import {
  getUploadDataFromFile,
  getUploadDataFromResponse,
  useMediaUploader,
} from "@niklasenb/azimio-media-client";
import {
  useAttachmentStore,
  useAuthStore,
  useCreateAttachment,
  useProjectStore,
  useSettingsStore,
  useUpdateAttachment,
} from "@amenda-domains/mutations";
import { useLocation, useNavigate } from "react-router-dom";

import { EditConstructionDetails } from "./ConstructionDetailsForm";
import { FileLimits } from "@amenda-components/FileUploads/common";
import { FileUploaderDropZone } from "@amenda-components/FileUploads";
import { FormAutoSaveSubmitProps } from "@amenda-components/PageBuilder";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { VALID_PDF_FORMATS } from "@amenda-types";
import { getProcessedUrl } from "@amenda-components/App/common";
import { isCreateNewConstructionDetail } from "./helpers";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { transformFormToAttachmentBase } from "@amenda-components/Gallery/common";
import { transitionsTheme } from "@amenda-styles/theme";

interface FileUploaderWrapperProps {
  isUploading: boolean;
  selectedAttachment?: any;
  handleDrop: (files: File[]) => void;
}

const FileUploaderWrapper: FC<FileUploaderWrapperProps> = ({
  isUploading,
  selectedAttachment,
  handleDrop,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const url = selectedAttachment?.extractedImages?.[0];
  const loading =
    isUploading ||
    Boolean(isLoading && !isNil(url)) ||
    Boolean(!isEmpty(selectedAttachment) && isNil(url));
  const processedUrl = getProcessedUrl({
    url,
    magicSize: "fullscreen",
    showLoader: true,
  });

  return (
    <FileUploaderDropZone
      wrapperClassName="w-full min-h-96 h-full"
      className={transitionsTheme({
        class: "relative flex h-full items-center justify-center bg-gray-50",
      })}
      maxFiles={1}
      maxFileSize={FileLimits["50MB"]}
      acceptedFileTypes={{
        "application/pdf": [],
      }}
      disabled={loading}
      onDrop={handleDrop}
    >
      {processedUrl && (
        <div className="absolute top-0 h-full w-full">
          <img
            className="h-full w-full object-contain"
            src={processedUrl}
            alt=""
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        </div>
      )}
      <div className="space-y-1 pb-8 text-center">
        <img
          className="mx-auto text-gray-400"
          src="/images/construction_details.svg"
          alt="construction details file-upload"
          style={{ width: "200px" }}
        />
        <p className="mx-auto max-w-xs pb-4 text-sm text-gray-600">
          <Trans>
            {t(
              loading
                ? "Uploading and extracting images from the file. This may take a few seconds. You can continue with the form while this is happening and save when you're ready."
                : "<strong>Drag and drop</strong> here or <strong>click to upload</strong>. Currently only <strong>PDF files</strong> are supported.",
            )}
          </Trans>
        </p>
        {loading ? (
          <div className="flex w-full justify-center pt-2">
            <Spinner spinnerSize="sm" />
          </div>
        ) : (
          <Button>
            <div className="flex w-full space-x-1">
              <PlusIcon className="h-4 w-4" />
              <span>{t("Upload Construction Drawing")}</span>
            </div>
          </Button>
        )}
      </div>
    </FileUploaderDropZone>
  );
};

export const NewConstructionDetails: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAuthStore((state) => state.loggedInUser);
  const { createAttachment } = useCreateAttachment();
  const { updateAttachment, loading } = useUpdateAttachment();
  const forms = useProjectStore((state) => state.forms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const uppy = useMediaUploader({
    tenantId: user.tenantId,
    path: "projects",
    autoProceed: true,
    allowedFileTypes: VALID_PDF_FORMATS,
    maxFileSize: FileLimits["50MB"],
    url: `${process.env.REACT_APP_BE_REST_URL}/files/`,
  });
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const [isUploading, setIsUploading] = useState(false);
  const projectId = useAttachmentStore(
    (state) => state.attachmentProject?.projectId,
  );
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const [selectedBtn, setSelectedBtn] = useState<"addAnother" | "submit">();

  const openCreateConstructionDetailModal =
    isCreateNewConstructionDetail(pathname);
  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const componentTree = groupComponentsByParent(components);
  const defaultShareType =
    mediaGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];
  const isDisabled = isUploading || !selectedAttachment?.id;

  const handleClose = () => {
    uppy.clear();
    setAttachmentProject({});
    navigate(-1);
  };

  const handleSubmit = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    if (isEmpty(sanitizedData) || !selectedAttachment?.id) {
      return;
    }

    await updateAttachment({
      input: {
        _id: selectedAttachment.id,
        ...transformFormToAttachmentBase(sanitizedData),
      },
    });
  };

  const handleSave = async (handleManualSubmit?: () => Promise<boolean>) => {
    if (!handleManualSubmit) return;

    const isSubmitted = await handleManualSubmit();
    if (isSubmitted) {
      handleClose();
    }
  };

  const handleAddAnother = async (
    handleManualSubmit?: () => Promise<boolean>,
    resetForm?: () => void,
  ) => {
    if (!handleManualSubmit || !resetForm) return;

    const isSubmitted = await handleManualSubmit();
    if (isSubmitted) {
      resetForm();
      setSelectedAttachment({});
      uppy.clear();
    }
  };

  const handleDrop = (files: File[]) => {
    uppy.addFiles(files);
    setIsUploading(true);
  };

  useEffect(() => {
    uppy.on("upload-success", async (file: any, resp: any) => {
      const { bucketName, uploadLocation, type } = await getUploadDataFromFile(
        user.tenantId,
        file,
      );
      const { filename } = getUploadDataFromResponse(resp);

      await createAttachment({
        input: {
          type,
          filename,
          uploadLocation,
          bucketname: bucketName,
          originalFilename: file.name,
          metadata: {
            ...(file.meta || {}),
            size: file.size,
          },
          formValues: {
            project: projectId,
          },
          share: {
            type: defaultShareType ?? GeneralAccessTypes.Everyone,
          },
        },
      });
      setIsUploading(false);
    });

    return () => {
      uppy.off("upload-success");
    };
  }, [uppy, defaultShareType, user.tenantId, projectId, createAttachment]);

  return (
    <FullScreenModal
      isOpen={openCreateConstructionDetailModal}
      onClose={handleClose}
    >
      <div className="h-full w-full">
        <div className="flex w-full justify-end p-4">
          <IconButtonBase variant="inverted" onClick={handleClose}>
            <XIcon className="h-8 w-8" />
          </IconButtonBase>
        </div>
        <h1 className="sticky text-center text-lg lg:text-xl">
          {t("Create New Detail")}
        </h1>
        <MaxWidthLayout
          wrapperClassName="h-[calc(100vh-6rem)] overflow-y-auto overscroll-y-contain py-6 xl:px-40 "
          className="grid h-fit grid-cols-1 gap-8 px-10 lg:grid-cols-2 xl:gap-14"
        >
          <div className="col-span-1">
            <FileUploaderWrapper
              isUploading={isUploading}
              selectedAttachment={selectedAttachment}
              handleDrop={handleDrop}
            />
          </div>
          <div className="col-span-1">
            <EditConstructionDetails
              components={components}
              componentTree={componentTree}
              globalProps={{
                loading,
                isDisabled,
              }}
              formValues={{
                project: projectId,
              }}
              handleSave={handleSubmit}
            >
              {({ reset, handleSubmitManually }) => (
                <Tooltip
                  id="constructionDetailSubmitBtn"
                  position="top"
                  hidden={!isDisabled}
                  message="Upload a file first to proceed"
                  className="flex w-full justify-end space-x-2 pt-4"
                >
                  <div className="w-1/2">
                    <Button
                      className="flex w-full justify-center border-2 border-gray-800"
                      disabled={isDisabled}
                      loading={selectedBtn === "addAnother" && loading}
                      onClick={() => {
                        setSelectedBtn("addAnother");
                        handleAddAnother(handleSubmitManually, reset);
                      }}
                    >
                      {t("Add another one")}
                    </Button>
                  </div>
                  <div className="w-1/2">
                    <Button
                      className="flex w-full justify-center"
                      disabled={isDisabled}
                      loading={selectedBtn === "submit" && loading}
                      variant="primary"
                      onClick={() => {
                        setSelectedBtn("submit");
                        handleSave(handleSubmitManually);
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </EditConstructionDetails>
          </div>
        </MaxWidthLayout>
      </div>
    </FullScreenModal>
  );
};
