import { FC, Fragment, ReactNode } from "react";
import { isArray, isString } from "lodash";

import clsx from "clsx";
import { isUrl } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  title?: string;
  container?: "row" | "column";
  description: string | ReactNode;
  alignContents?: "right" | "left" | "center" | "justify";
  fontSize?: "xs" | "sm" | "base" | "lg";
  style?: Record<string, any>;
  preserveLineBreaks?: boolean;
}

export const TitleAndDescription: FC<Props> = ({
  title,
  preserveLineBreaks,
  description: ogDescription,
  className = "",
  fontSize = "base",
  alignContents = "left",
  container = "row",
}) => {
  const { t } = useTranslation();
  let description = ogDescription;
  const fontCss = `text-${fontSize}`;
  const alignCss = `text-${alignContents}`;

  if (isArray(description)) {
    description = description.map((d) => d.label).join(", ");
  } else if (isString(description)) {
    description = isUrl(description) ? description : t(description);
  }

  return (
    <div
      className={clsx(
        "w-full text-gray-900",
        fontCss,
        {
          [alignCss]: container === "row",
        },
        className,
      )}
    >
      {container === "row" ? (
        <Fragment>
          {title && <span className="amenda-component-label">{t(title)}</span>}
          {description}
        </Fragment>
      ) : (
        <Fragment>
          {title && <dt className="amenda-component-label">{t(title)}</dt>}
          <dd
            className={clsx("mt-1", {
              "overflow-y-auto overscroll-contain whitespace-pre-line":
                Boolean(preserveLineBreaks),
            })}
          >
            {description}
          </dd>
        </Fragment>
      )}
    </div>
  );
};

export const TitleAndDescriptionFixedUnits: FC<Props> = ({
  title,
  style,
  description,
  className = "",
  alignContents = "left",
  container = "row",
}) => {
  const { t } = useTranslation();

  const alignCss = `text-${alignContents}`;

  if (isArray(description)) {
    description = description.map((d) => d.label).join(", ");
  }
  return (
    <div
      className={clsx("w-full text-gray-500", className, {
        [alignCss]: container === "row",
      })}
      style={style}
    >
      {container === "row" ? (
        <Fragment>
          {title && <span className="mr-1 text-gray-900">{t(title)}</span>}
          {description}
        </Fragment>
      ) : (
        <Fragment>
          {title && <dt className="text-gray-900">{t(title)}</dt>}
          <dd className="mt-1">{description}</dd>
        </Fragment>
      )}
    </div>
  );
};

interface TitleAndSubtitleProps {
  title: string;
  children: ReactNode;
}

export const TitleAndSubtitle: FC<TitleAndSubtitleProps> = ({
  title,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mr-10 text-left text-sm">
      <dt className="text-gray-500">{t(title)}</dt>
      <dd className="mt-0.5 text-gray-800">{children}</dd>
    </div>
  );
};
