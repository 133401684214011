import { ErrorMessage } from "./ErrorMessage";
import { FC } from "react";
import { Option } from "@amenda-types";
import { Star } from "lucide-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  description?: string;
  error?: string;
  id: string;
  label: string;
  horizontal?: boolean;
  options: Option[];
  value: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  variant?: "radio" | "star";
}

export const RadioButton: FC<Props> = ({
  description,
  error,
  id,
  label,
  options,
  value,
  disabled,
  horizontal = false,
  onChange,
  onBlur,
  variant = "radio",
}) => {
  const { t } = useTranslation();

  const handleChange = (newValue: string) => () => {
    if (value === newValue) {
      onChange?.(""); // Reset value if clicked again
    } else {
      onChange?.(newValue);
    }
    onBlur?.();
  };

  return (
    <div className="mb-2">
      <legend className="amenda-component-label">{t(label)}</legend>
      {description && (
        <p className="mt-1 text-sm text-gray-900">{t(description)}</p>
      )}
      <div
        className={clsx("mt-1", {
          "flex flex-wrap space-y-1": !!horizontal && variant === "radio",
          "flex flex-col space-y-2": !horizontal && variant === "radio",
          "flex flex-row space-x-1": variant === "star",
        })}
        onBlur={onBlur}
      >
        {variant === "radio" ? (
          options.map((option) => (
            <div
              className={clsx("flex items-center", {
                "ml-2": !!horizontal,
              })}
              key={option.value}
            >
              <input
                id={option.value}
                name={id}
                type="radio"
                className={clsx("-mt-1 h-4 w-4 ring-0 focus:ring-0", {
                  "text-gray-900": !disabled,
                  "cursor-not-allowed text-gray-400": disabled,
                })}
                checked={option.value === value}
                disabled={disabled}
                onChange={handleChange(option.value)}
              />
              <label
                htmlFor={option.value}
                className="ml-2 block text-sm text-gray-900"
              >
                {t(option.label)}
              </label>
            </div>
          ))
        ) : (
          <div className="flex items-center space-x-2">
            {" "}
            {options.map((option, index) => (
              <Star
                key={index}
                className={clsx("cursor-pointer text-xl", {
                  "stroke-gray-900": index >= parseInt(value),
                  "fill-gray-900 stroke-gray-900": index < parseInt(value),
                })}
                onClick={() => !disabled && handleChange(String(index + 1))()}
              />
            ))}
          </div>
        )}
      </div>
      <ErrorMessage id={id} error={error} />
    </div>
  );
};
