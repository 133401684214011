import { Accordion, IconButton } from "@amenda-components/App";
import {
  AvailableForms,
  FormComponentTypes,
  SearchCollections,
  getLogicalOperators,
} from "@amenda-constants";
import {
  KeywordCheckboxWrapper,
  SearchComponentsWrapper,
} from "@amenda-components/Shared/SearchComponents";
import { getComponentsFromForms, getSearchComponents } from "@amenda-utils";
import {
  isSearchFilterEmpty,
  processFormPermissions,
  processSearchFilters,
} from "@amenda-components/Shared/common";
import {
  useAppStore,
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { Controller } from "react-hook-form";
import { FC } from "react";
import { LogicalOperators } from "@amenda-types";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { isEmpty } from "lodash";
import { useFormAutoSave } from "@amenda-components/PageBuilder";

interface Props {
  isProject: boolean;
  handleReset: () => void;
}

export const SidebarFilters: FC<Props> = ({ isProject, handleReset }) => {
  const { reset, control, submitButtonRef, handleFormSubmit } =
    useFormAutoSave();
  const projects = useProjectStore((state) => state.projects);
  const attachments = useAttachmentStore((state) => state.attachments);
  const appForms = useProjectStore((state) => state.forms);
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole,
  );
  const { searchTerm, sidebarFilters } = useSidebarFiltersWithPath();
  const isSearchingProjects = useProjectStore((state) => state.isSearching);
  const isSearchingAttachments = useAttachmentStore(
    (state) => state.isSearching,
  );

  const availableForm = isProject
    ? AvailableForms.Project
    : AvailableForms.Gallery;
  const formValues =
    availableForm === AvailableForms.Project
      ? projects.map((p) => p?.formValues)
      : attachments.map((a) => a?.formValues);
  const forms = appForms?.[availableForm];
  const permissions = currentUserSystemRole?.permissions || {};
  const components = getComponentsFromForms(forms);
  const allowedComponents = processFormPermissions(permissions)(components);
  const searchableComponents = getSearchComponents(allowedComponents);
  const options = getLogicalOperators(isProject);
  const hasSearchFilters =
    !isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters);
  const isSearching = isProject ? isSearchingProjects : isSearchingAttachments;
  const searchCollection = isProject
    ? SearchCollections.Projects
    : SearchCollections.Attachments;

  const handleClose = async () => {
    reset?.({});
    setOpenSidebar(false);
    await handleReset();
  };

  return (
    <form className="relative w-full" onSubmit={handleFormSubmit}>
      <div className="sticky right-0 top-0 z-10 mb-2 flex w-full justify-between border-b border-gray-200 bg-white px-3 pb-2">
        <div></div>
        <IconButton
          Icon={XMarkIcon}
          label="Close"
          variant="default"
          onClick={handleClose}
        />
      </div>
      <div className="px-2 pb-4 lg:pb-44">
        {searchableComponents.map((component, index) => {
          return (
            <div className="flex flex-col" key={component.id}>
              <Controller
                name={component.id}
                control={control}
                render={({ field: { onChange, value } }) => {
                  const filterValue = value || {};
                  const hideBorder = index + 1 === searchableComponents.length;

                  const handleChange = (value: any) => {
                    const other = Boolean(component?.properties?.isNested)
                      ? filterValue?.operation
                        ? { operation: filterValue.operation }
                        : {
                            operation: LogicalOperators.OR,
                          }
                      : {};

                    const filters = processSearchFilters({
                      component,
                      value,
                      other,
                    });
                    onChange(filters);
                  };

                  const handleSelectedOption = (option: LogicalOperators) => {
                    const operation =
                      option !== LogicalOperators.RESET
                        ? option
                        : LogicalOperators.AND;

                    onChange({
                      ...value,
                      operation,
                    });
                  };

                  return (
                    <Accordion
                      options={options}
                      defaultOpen={value}
                      component={component}
                      hideBorder={hideBorder}
                      selectedOption={value?.operation}
                      title={component?.properties?.label || ""}
                      showOperationsMenu={!isEmpty(filterValue.value)}
                      handleSelectedOption={(e) =>
                        handleSelectedOption(e.value)
                      }
                    >
                      {component?.component === FormComponentTypes.Keyword ? (
                        <KeywordCheckboxWrapper
                          values={filterValue.value}
                          operation={filterValue.operation}
                          component={component}
                          formValues={formValues}
                          isSearching={isSearching}
                          searchCollection={searchCollection}
                          hasSearchFilters={hasSearchFilters}
                          onChange={handleChange}
                        />
                      ) : (
                        <SearchComponentsWrapper
                          values={filterValue.value}
                          operation={filterValue.operation}
                          component={component}
                          formValues={formValues}
                          isSearching={isSearching}
                          searchCollection={searchCollection}
                          hasSearchFilters={hasSearchFilters}
                          onChange={handleChange}
                        />
                      )}
                    </Accordion>
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <button type="submit" hidden ref={submitButtonRef} />
    </form>
  );
};
