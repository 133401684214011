import { MainContainer, SidebarContainer } from "@amenda-components/Shared";

import { FC } from "react";
import { FormBuilderCategorySidebar } from "./FormBuilderCategorySidebar";
import { FormBuilderComponentEditor } from "./FormBuilderComponentEditor";
import { FormBuilderCreateFormModal } from "./FormBuilderCreateFormModal";
import { FormBuilderSectionsSidebar } from "./FormBuilderSectionsSidebar";
import { useAppStore } from "@amenda-domains/mutations";

export const FormBuilder: FC = () => {
  const selectedCategory = useAppStore(
    (state) => state.formBuilderState.selectedCategory,
  );

  return (
    <div className="mt-4 flex h-[calc(100vh-11rem)] w-full">
      <FormBuilderCreateFormModal />
      <SidebarContainer className="w-72 border-r">
        {selectedCategory ? (
          <FormBuilderSectionsSidebar />
        ) : (
          <FormBuilderCategorySidebar />
        )}
      </SidebarContainer>
      <MainContainer>
        <FormBuilderComponentEditor />
      </MainContainer>
    </div>
  );
};
