import { AllowedAttachmentType, AppRoutes } from "@amenda-types";
import { AvailableForms, PaginationLimit } from "@amenda-constants";
import { CollectionDropdown, SharedLayout } from "@amenda-components/Shared";
import {
  ConstructionDetailsCard,
  ConstructionDetailsListCard,
} from "./ConstructionDetailsCard";
import {
  getComponentsById,
  getComponentsFromForms,
  isSafeCollection,
} from "@amenda-utils";
import {
  useAttachmentStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { ConstructionDetailsActionBar } from "./ConstructionDetailsActionBar";
import { ConstructionDetailsProps } from "./types";
import { ConstructionIcon } from "lucide-react";
import { FC } from "react";
import { FormAutoSaveSubmitProps } from "@amenda-components/PageBuilder";
import isEmpty from "lodash/isEmpty";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetAllAttachments } from "@amenda-domains/queries";

export const ConstructionDetails: FC<ConstructionDetailsProps> = ({
  attachments,
  canUpload,
  canCreateCollection,
  pagination,
  isCollection,
  collectionType,
  label = "Construction Details",
  searchAttachments,
  fetchAttachments,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const { getAllAttachments } = useGetAllAttachments();
  const layoutView = useAttachmentStore((state) => state.layoutView);
  const {
    searchTerm,
    sidebarFilters,
    setSearchTerm,
    setSidebarFilters,
    clearSidebarFilters,
  } = useSidebarFiltersWithPath();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const selectAllAttachments = useAttachmentStore(
    (state) => state.setSelectAllAttachments,
  );

  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const componentsById = getComponentsById(components);
  const selectedCollection = selectedCollectionByType?.[collectionType];

  const handleCreateNew = () => {
    navigate(AppRoutes.ConstructionDetailsNew);
  };

  const handleSearch = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const { searchTerm, ...rest } = sanitizedData;
    if (!searchAttachments) return;
    setSearchTerm(searchTerm);
    setSidebarFilters(rest);
    await searchAttachments(searchTerm, rest);
  };

  const handleClearSearch = async () => {
    if (!searchAttachments) return;
    clearSidebarFilters();
    await searchAttachments("", {});
  };

  const handleResourceIds = async (args: any) => {
    let attachmentIds: string[] = [];

    if (fetchAttachments) {
      const attachments = await fetchAttachments({
        formValues: args,
        attachmentType: AllowedAttachmentType.pdf,
        callback: (data: any) => {},
      });

      attachmentIds =
        attachments?.map((attachment: any) => attachment.id) ?? [];
    }
    return attachmentIds;
  };

  const handleGetNextPage = async () => {
    const args: any = {
      next: pagination?.next,
      limit: PaginationLimit.attachments,
      attachmentType: AllowedAttachmentType.pdf,
    };
    if (isCollection) {
      args.ids = selectedCollection?.resourceIds;
    }
    await getAllAttachments(args);
  };

  return (
    <SharedLayout
      view={layoutView}
      isFetching={isFetching}
      isSearching={isSearching}
      hasNavigationSearch={true}
      components={components}
      hasSearchFilters={
        !isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)
      }
      searchTerm={searchTerm}
      sidebarFilters={sidebarFilters}
      HelperIcon={ConstructionIcon}
      searchPlaceholder="Search construction details"
      helperMessage="No Construction details found"
      helperDescription="Please add construction details to view them here"
      helperDescriptionSearch="Try changing your search filters"
      helperActionText={canUpload ? "Add new detail" : undefined}
      helperOnClick={canUpload ? handleCreateNew : undefined}
      resources={attachments}
      totalSelectedResources={selectedAttachments.length}
      selectAllResources={selectAllAttachments}
      clearSelectedResources={clearSelectedAttachments}
      hasNextPage={
        isSearchFilterEmpty(sidebarFilters) &&
        isEmpty(searchTerm) &&
        isSafeCollection(
          Boolean(isCollection),
          selectedCollection?.resourceIds,
        ) &&
        Boolean(
          pagination?.hasNext &&
            pagination?.docsCount &&
            attachments.length < pagination.docsCount,
        )
      }
      hasNoPages={
        !isEmpty(pagination) &&
        !Boolean(pagination?.hasNext) &&
        !Boolean(pagination?.hasPrevious)
      }
      getNextPage={handleGetNextPage}
      collectionDropdownChildren={
        Boolean(canCreateCollection) ? (
          <CollectionDropdown
            label={label}
            goBackLabel="All construction details"
            resourceIds={selectedAttachments}
            collectionType={collectionType}
            routes={{
              goBack: AppRoutes.ConstructionDetails,
              collection: AppRoutes.ConstructionDetailsCollection,
            }}
            getResourceIds={handleResourceIds}
            clearSelectedResources={clearSelectedAttachments}
          />
        ) : (
          <h3 className="amenda-form-heading pt-5">{t(label)}</h3>
        )
      }
      handleNavigationSearch={handleSearch}
      handleClearNavigationSearch={handleClearSearch}
      navigationChildren={
        <ConstructionDetailsActionBar
          canUpload={canUpload}
          collectionType={collectionType}
        />
      }
      gridChildren={(attachment) => (
        <ConstructionDetailsCard
          attachment={attachment}
          componentsById={componentsById}
        />
      )}
      listChildren={(attachment) => (
        <ConstructionDetailsListCard
          attachment={attachment}
          componentsById={componentsById}
        />
      )}
    />
  );
};
