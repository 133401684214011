import { Colors } from "@amenda-styles/theme";
import { FC } from "react";
import { Tooltip } from "./Tooltip";
import clsx from "clsx";
import { isNil } from "lodash";

interface Props {
  count: number;
  className?: string;
  variant?: keyof Colors;
  message?: string;
}

const CounterBadge: FC<Props> = ({ variant, count, className }) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center rounded-full text-xs",
        className,
        {
          "bg-red-500 text-white": variant === "danger",
          "bg-green-700 text-white": variant === "success",
        },
      )}
    >
      {count}
    </div>
  );
};

export const Counter: FC<Props> = ({
  count,
  message,
  className = "w-5 h-5",
  variant = "danger",
}) => {
  if (isNil(count) || count <= 0) return null;
  if (message) {
    return (
      <Tooltip id="counterBadge" message={message}>
        <CounterBadge variant={variant} count={count} className={className} />
      </Tooltip>
    );
  }
  return <CounterBadge variant={variant} count={count} className={className} />;
};
