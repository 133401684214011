import {
  ComponentSearchModal,
  SearchComponentItemProps,
} from "@amenda-components/SearchComponents/ComponentDropdownSearch";
import { FC, SyntheticEvent } from "react";
import { Option, PageComponentProps } from "@amenda-types";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";
import { sortSimilaritySearchOptions } from "@amenda-components/Projects/common";
import { useTranslation } from "react-i18next";

interface Props {
  components: PageComponentProps[];
  selectedComponentsIds: string[];
  toggleComponents: (component: PageComponentProps) => void;
}

type OptionType = PageComponentProps & Option;
type GetSearchComponentArgs = Omit<Props, "components">;

const getSearchComponent =
  ({ selectedComponentsIds, toggleComponents }: GetSearchComponentArgs) =>
  ({
    position,
    formLabel,
    options,
    clearSearchTerm,
  }: SearchComponentItemProps) => {
    const { t } = useTranslation();

    const handleClick =
      ({ value, label, ...rest }: OptionType) =>
      (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        toggleComponents(rest);
        clearSearchTerm();
      };

    return (
      <div className="w-full">
        <div
          className={clsx(
            "w-full border-b border-gray-100 pb-2 pl-3 pt-4 text-sm text-gray-500",
            {
              "pt-4": position !== 0,
              "pt-2": position === 0,
            },
          )}
        >
          {formLabel}
        </div>
        {[...options]
          .sort(sortSimilaritySearchOptions(selectedComponentsIds))
          .map((option) => {
            const isSelected = selectedComponentsIds.includes(option.value);
            return (
              <button
                key={option.value}
                onClick={handleClick(option)}
                className={clsx(
                  "flex w-full items-center justify-between py-2 pl-6 text-gray-700 hover:bg-gray-900 hover:text-white",
                  {
                    "bg-gray-200 pr-4": isSelected,
                  },
                )}
              >
                <span className="text-sm">{t(option.label)}</span>
                {isSelected && <XMarkIcon className="h-4 w-4" />}
              </button>
            );
          })}
      </div>
    );
  };

export const BulkEditorComponentSearch: FC<Props> = ({
  components,
  ...props
}) => {
  return (
    <ComponentSearchModal
      components={components}
      formNameById={{}}
      label="Add field"
      Icon={PlusIcon}
      SearchComponentItem={getSearchComponent(props)}
      className="border bg-gray-800 p-1 text-sm text-white hover:bg-gray-900 hover:text-white"
    />
  );
};
