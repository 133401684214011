import { Avatar, HoverListSelector } from "@amenda-components/App";
import { flattenUserDetails, getUserName, isSelectedUser } from "./common";
import { useNavigate, useParams } from "react-router-dom";

import { AllowedContactType } from "@amenda-types";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { ContactDirectoryProps } from "./types";
import { FC } from "react";
import clsx from "clsx";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props
  extends Pick<ContactDirectoryProps, "rootRoute" | "collectionRoute"> {
  contact: any;
  isCollection: boolean;
}

export const ContactListCard: FC<Props> = ({
  contact,
  isCollection,
  rootRoute,
  collectionRoute,
}) => {
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId?: string }>();
  const toggleSelectedUser = useUsersStore((state) => state.toggleSelectedUser);
  const selectedUsers = useUsersStore((state) => state.selectedUsers);
  const setShiftSelectedUser = useUsersStore(
    (state) => state.setShiftSelectedUser,
  );
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );

  const user = flattenUserDetails(contact);
  const name = getUserName(user);

  const handleClick = () => {
    let path = isCollection ? `${collectionRoute}/${collectionId}` : rootRoute;
    path = contact?.id ? `${path}/${contact.id}` : path;

    setOpenContactListSlideOver(false);
    navigate(path);
  };

  const handleSelect = () => {
    toggleSelectedUser(contact);
  };

  const handleShiftSelection = () => {
    setShiftSelectedUser(contact);
  };

  return (
    <HoverListSelector
      isSelected={isSelectedUser(selectedUsers ?? [], contact)}
      className="w-full cursor-pointer"
      handleSelect={handleSelect}
      handleClick={handleClick}
      handleShiftSelect={handleShiftSelection}
    >
      <div className="relative flex items-center space-x-3 truncate px-1 py-0 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-50 md:px-6">
        <Avatar
          Icon={BuildingOfficeIcon}
          name={AllowedContactType.company !== contact.type ? name : ""}
          src={user?.photoURL}
          iconClassName="h-7 w-7"
          className={clsx("h-10 min-h-10 w-10 min-w-10", {
            "text-gray-600": contact.type === AllowedContactType.company,
          })}
        />
        <div className="min-w-0 flex-1">
          <p className="truncate text-base text-gray-900">{name}</p>
        </div>
      </div>
    </HoverListSelector>
  );
};
