import { CostGroupTypes, processCostGroupPermissions } from "./common";
import { FC, useEffect } from "react";
import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { CalculatorIcon } from "@heroicons/react/24/solid";
import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { HelperMessage } from "@amenda-components/App";
import { MiniSwitch } from "@amenda-components/FormComponents";
import { ReadOnlyCostGroupRowBody } from "./ReadOnlyCostGroupRowBody";
import { ReadOnlySelectCostGroupVersion } from "./ReadOnlySelectCostGroupVersion";
import { isBgfComponent } from "@amenda-components/Projects/common";
import isEmpty from "lodash/isEmpty";
import { keyBy } from "lodash";
import { useGetAllCostGroups } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@amenda-components/App";
const ReadOnlySwitch: FC = () => {
  const { t } = useTranslation();
  const { calculateWithBGF } = useProjectStore(
    (state) => state.projectCostGroupsReadonlyData,
  );
  const setCalculateWithBGF = useProjectStore(
    (state) => state.setCalculateWithBGF,
  );

  return (
    <div className="mt-1 flex w-full items-center space-x-2">
      <Tooltip
        id="bgf-only"
        message={t("Enable to overwrite Reference Values with BGF")}
      >
        <MiniSwitch
          label=""
          value={calculateWithBGF}
          onChange={(value) => {
            setCalculateWithBGF(value);
          }}
        />
      </Tooltip>
      <span className="text-sm">{t("BGF only")}</span>
    </div>
  );
};

export const ReadOnlyProjectCostGroups: FC<{ projectId: string }> = ({
  projectId,
}) => {
  const forms = useProjectStore((state) => state.forms);
  const { getAllCostGroups } = useGetAllCostGroups();
  const currentUserSystemRole = useSettingsStore(
    (state) => state.currentUserSystemRole,
  );

  const costGroupCostForms = forms?.[AvailableForms.CostGroupsCost];
  const costGroupQuantityForms =
    forms?.[AvailableForms.CostGroupsQuantity] || [];
  const costGroupQuantityComponents = getComponentsFromForms(
    costGroupQuantityForms,
  );
  const quantityComponentsById = keyBy(costGroupQuantityComponents, "id");
  const formComponents = getComponentsFromForms(costGroupCostForms);
  const permissions = currentUserSystemRole?.permissions || {};
  const formComponentTree = groupComponentsByParent(
    formComponents,
    processCostGroupPermissions(permissions),
  );
  const bgfId = costGroupQuantityComponents.find((c) => isBgfComponent(c))?.id;

  useEffect(() => {
    if (projectId) {
      getAllCostGroups({
        isDeleted: false,
        resourceId: projectId,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getAllCostGroups]);

  if (isEmpty(formComponentTree.components)) {
    return (
      <HelperMessage
        message="Restricted Form"
        helpText="You don't have permissions to view this form"
        Icon={CalculatorIcon}
      />
    );
  }
  return (
    <ComponentsTreeView
      showTooltip={true}
      readOnly={true}
      components={formComponentTree.components as any[]}
      label="cost group"
      className="max-h-[80vh]"
      columnClassName="w-[16rem] bg-white"
      searchPlaceholder="Kostengruppe filtern..."
      columns={CostGroupTypes.map((type) => ({
        id: type,
        label: type,
      }))}
      headerChildren={<ReadOnlySwitch />}
      RowBody={ReadOnlyCostGroupRowBody}
      HeaderComponent={ReadOnlySelectCostGroupVersion}
      globalData={{
        bgfId,
        quantityComponentsById,
      }}
    />
  );
};
